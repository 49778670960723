export interface BaseWidth {
  appSidebarWidth: string;
  appSidebarWidthCollapsed: string;
  appSidebarNaviIconWidth: string;
  appHeaderMinWidth: string;
}

export const baseWidth: BaseWidth = {
  appSidebarWidth: "200px",
  appSidebarWidthCollapsed: "60px",
  appSidebarNaviIconWidth: "2.8rem",
  appHeaderMinWidth: "450px",
};
