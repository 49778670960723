import { AdminReleaseLang } from "./Release.dto";
import { LoLang } from "../common/common.types";

export const isAdminReleaseLang = (lang: LoLang | string): lang is AdminReleaseLang => {
  switch (lang) {
    case LoLang.KO:
    case LoLang.EN:
      return true;
    default:
      return false;
  }
};
