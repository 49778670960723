import { GetMyInfoRequest, GetMyInfoResponse } from "./User.dto";
import { SafetyUserUrl } from "./User.url";
import { restReq } from "../common/cyan.types";
import { FxApiRequestData, FxRequestData } from "../common/flax.types";

export class SafetyUserFlax {
  public static getMyInfo(data: FxRequestData<GetMyInfoRequest>): FxApiRequestData<GetMyInfoResponse> {
    return {
      ...restReq(process.env.REACT_APP_SAFETY_BE_URL, SafetyUserUrl.getMyInfo, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }
}
