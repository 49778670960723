import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setBaseUrl, setDefaultHeaders } from "@coralblack/flax";
import { LoLang } from "../../common/common.types";
import { AwakeAuthTokenResponse, CreateAuthTokenResponse } from "../../sso/AuthToken.dto";

// Set baseUrl
setBaseUrl(process.env.REACT_APP_CDMS_BE_URL || process.env.REACT_APP_CDMS_URL || process.env.REACT_APP_SERVICE_HOST || "http://127.0.0.1");

export interface SessionState {
  token: string | null;
  expiryAt: Date | null;
}

export interface LocaleState {
  lolang: LoLang;
}

export const DEVICE_KEY = "cream:auth:device:key";
export const SESSION_KEY = "cream:auth";
export const LOCALE_LANG_KEY = "LoLang";
const stored = localStorage.getItem(SESSION_KEY);

export const getBrowserDefaultLanguage = () => {
  switch (navigator.language.toLocaleLowerCase()) {
    case "ko":
    case "ko-kr":
      return LoLang.KO;
    case "fr":
    case "fr-fr":
      return LoLang.FR;
    case "nl":
    case "nl-nl":
      return LoLang.NL;
    default:
      return LoLang.EN;
  }
};

if (!localStorage.getItem(LOCALE_LANG_KEY)) {
  localStorage.setItem(LOCALE_LANG_KEY, getBrowserDefaultLanguage());
}

const language = localStorage.getItem(LOCALE_LANG_KEY) as LoLang;
const initialState: SessionState = Object.assign(
  {
    token: null,
    expiryAt: null,
  },
  stored ? JSON.parse(stored) : {}
);

const localeInitialState: LocaleState = Object.assign({ lolang: LoLang.KO }, language ? { lolang: language } : {});

document.documentElement?.setAttribute("lang", String(localeInitialState.lolang).toLowerCase());

export const setCookie = (key: string, value: string, expiryAt: Date) => {
  document.cookie = `${key}=${value}; expires=${expiryAt.toUTCString()}; path=/;`;
};

export const getCookie = (key: string): string => {
  const cookie = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");

  return cookie ? cookie.pop() : "";
};

export const configurableDefaultHeaders = (headers?: { [key: string]: string }) =>
  Object.assign(
    {
      Authorization: initialState.token,
      Lang: language || LoLang.KO,
      "Timezone-Offset": -new Date().getTimezoneOffset(),
    },
    headers || {}
  ) as { [key: string]: string };

setDefaultHeaders(configurableDefaultHeaders());

export const setAdditionalDefaultHeaders = (headers: { [key: string]: string }) => setDefaultHeaders(headers);

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    signIn(state, action: PayloadAction<{ redirectTo: string } & CreateAuthTokenResponse>) {
      state.token = action.payload.authToken.token;
      state.expiryAt = action.payload.authToken.expiryAt;

      localStorage.setItem(SESSION_KEY, JSON.stringify({ ...state, redirectTo: undefined }));

      if (action.payload.redirectTo) {
        if (action.payload.redirectTo.includes(":")) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_, restProtocol] = action.payload.redirectTo.split(":");
          const [hostname] = (restProtocol || "").slice(2).split("/");
          const domain = String((hostname || "").split(".").slice(-2)[0]).toLowerCase(); // TLD는 1차(.com, .dev, .io, ..)만으로 간주함

          if (
            !["mavencdms", "mavenclinical"]
              .concat(
                ["local", "shared", "develop"].includes(process.env.REACT_APP_STAGE || process.env.VITE_APP_STAGE)
                  ? ["localhost", "127.0.0.1"]
                  : []
              )
              .includes(domain)
          ) {
            document.location.href = "/";
            return;
          }
        }

        document.location.href = action.payload.redirectTo;
      } else {
        document.location.reload();
      }
    },
    signOut(state) {
      state.token = null;
      state.expiryAt = null;

      localStorage.removeItem(SESSION_KEY);
      document.location.href = "/";
    },
    refreshSignIn(state, action: PayloadAction<{} & CreateAuthTokenResponse>) {
      state.token = action.payload.authToken.token;
      state.expiryAt = action.payload.authToken.expiryAt;

      localStorage.setItem(SESSION_KEY, JSON.stringify({ ...state, redirectTo: undefined }));

      setDefaultHeaders(configurableDefaultHeaders({ Authorization: action.payload.authToken.token }));
    },
    updateExpiryAt(state, action: PayloadAction<{} & AwakeAuthTokenResponse>) {
      state.expiryAt = action.payload.authToken.expiryAt;

      localStorage.setItem(SESSION_KEY, JSON.stringify({ ...state, redirectTo: undefined }));
    },
  },
});

export const localeSlice = createSlice({
  name: "locale",
  initialState: localeInitialState,
  reducers: {
    changeLocaleLanguage(state, action: PayloadAction<{} & { lolang: LoLang }>) {
      state.lolang = action.payload.lolang;

      localStorage.setItem(LOCALE_LANG_KEY, state.lolang);
      document.documentElement?.setAttribute("lang", String(state.lolang).toLowerCase());
    },
  },
});
