import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_IP_INFO_LANG: {
  STUDY_IP_MANAGEMENT_BREADCRUMB: MvnCodeFE;
  STUDY_IP_ACCOUNTABILITY_BREADCRUMB: MvnCodeFE;
  STUDY_IP_INFO_LIST_BTN_ADD: MvnCodeFE;
  STUDY_IP_INFO_ADD_TITLE_IP_INFO: MvnCodeFE;
  STUDY_IP_INFO_ADD_LABEL_TYPE: MvnCodeFE;
  STUDY_IP_INFO_ADD_TYPE_PLACEHOLDER: MvnCodeFE;
  STUDY_IP_INFO_ADD_LABEL_NO: MvnCodeFE;
  STUDY_IP_INFO_ADD_NO_PLACEHOLDER: MvnCodeFE;
  STUDY_IP_INFO_ADD_LABEL_NAME: MvnCodeFE;
  STUDY_IP_INFO_ADD_NAME_PLACEHOLDER: MvnCodeFE;
  STUDY_IP_INFO_ADD_LABEL_UNIT: MvnCodeFE;
  STUDY_IP_INFO_ADD_UNIT_PLACEHOLDER: MvnCodeFE;
  STUDY_IP_INFO_ADD_LABEL_CAPACITY: MvnCodeFE;
  STUDY_IP_INFO_ADD_CAPACITY_PLACEHOLDER: MvnCodeFE;
  STUDY_IP_INFO_ADD_LABEL_COUNT_PER_PACK: MvnCodeFE;
  STUDY_IP_INFO_ADD_COUNTER_PER_PACK_PLACEHOLDER: MvnCodeFE;
  STUDY_IP_INFO_ADD_SUCC: MvnCodeFE;
  STUDY_IP_INFO_ADD_FAIL: MvnCodeFE;
  STUDY_IP_INFO_ADD_LABEL_PACKAGEUNIT: MvnCodeFE;
  STUDY_IP_INFO_ADD_PACKAGEUNIT_PLACEHOLDER: MvnCodeFE;
  STUDY_IP_INFO_ADD_LABEL_FORMULATION: MvnCodeFE;
  STUDY_IP_INFO_ADD_FORMULATION_PLACEHOLDER: MvnCodeFE;
  STUDY_IP_INFO_EDIT_TITLE_IP_INFO: MvnCodeFE;
  STUDY_IP_INFO_EDIT_SUCC: MvnCodeFE;
  STUDY_IP_INFO_EDIT_FAIL: MvnCodeFE;
} = {
  STUDY_IP_MANAGEMENT_BREADCRUMB: {
    ko: "IP 배정, 불출, 반납 등 IP 상태를 관리하고, 관련 문서를 출력할 수 있습니다.",
    en: "Manage IP status and export relevant documents.",
  },
  STUDY_IP_ACCOUNTABILITY_BREADCRUMB: {
    ko: "IP 수불기록을 다운로드 하거나 불출/반납/CRA 서명 정보를 조회할 수 있습니다.",
    en: "Download IP Accountability and inquire dispense/return/CRA signature relevant IP.",
  },
  STUDY_IP_INFO_LIST_BTN_ADD: {
    ko: "IP 정보 추가",
    en: "Add IP Info",
  },
  STUDY_IP_INFO_ADD_TITLE_IP_INFO: {
    ko: "IP 정보",
    en: "IP Info",
  },
  STUDY_IP_INFO_ADD_LABEL_TYPE: {
    ko: "유형",
    en: "Type",
  },
  STUDY_IP_INFO_ADD_TYPE_PLACEHOLDER: {
    ko: "IP의 유형을 선택해 주세요.",
    en: "Please select type.",
  },
  STUDY_IP_INFO_ADD_LABEL_NO: {
    ko: "No.",
    en: "No.",
  },
  STUDY_IP_INFO_ADD_NO_PLACEHOLDER: {
    ko: "No.를 입력해 주세요.",
    en: "Please enter a number.",
  },
  STUDY_IP_INFO_ADD_LABEL_NAME: {
    ko: "이름",
    en: "Name",
  },
  STUDY_IP_INFO_ADD_NAME_PLACEHOLDER: {
    ko: "이름을 입력해 주세요.",
    en: "Please enter a name.",
  },
  STUDY_IP_INFO_ADD_LABEL_UNIT: {
    ko: "단위",
    en: "Unit",
  },
  STUDY_IP_INFO_ADD_UNIT_PLACEHOLDER: {
    ko: "IP의 단위을 입력해 주세요.",
    en: "Please enter a unit.",
  },
  STUDY_IP_INFO_ADD_LABEL_CAPACITY: {
    ko: "용량",
    en: "Quantity",
  },
  STUDY_IP_INFO_ADD_CAPACITY_PLACEHOLDER: {
    ko: "IP의 용량을 입력해 주세요.",
    en: "Please enter a quantity.",
  },
  STUDY_IP_INFO_ADD_LABEL_COUNT_PER_PACK: {
    ko: "팩당 개수",
    en: "Count per Pack",
  },
  STUDY_IP_INFO_ADD_COUNTER_PER_PACK_PLACEHOLDER: {
    ko: "팩당 개수를 입력해 주세요.",
    en: "Please enter count per pack.",
  },
  STUDY_IP_INFO_ADD_SUCC: {
    ko: "IP 정보를 추가했습니다.",
    en: "IP information has been added successfully.",
  },
  STUDY_IP_INFO_ADD_FAIL: {
    ko: "IP 정보를 추가에 실패했습니다. ({message})",
    en: "Failed to add IP information. ({message})",
  },
  STUDY_IP_INFO_ADD_LABEL_PACKAGEUNIT: {
    ko: "포장단위",
    en: "Package Unit",
  },
  STUDY_IP_INFO_ADD_PACKAGEUNIT_PLACEHOLDER: {
    ko: "IP의 포장단위를 입력해 주세요.",
    en: "Please enter package unit of IP.",
  },
  STUDY_IP_INFO_ADD_LABEL_FORMULATION: {
    ko: "제형",
    en: "Formulation",
  },
  STUDY_IP_INFO_ADD_FORMULATION_PLACEHOLDER: {
    ko: "IP의 제형을 입력해 주세요.",
    en: "Please enter formulation of IP.",
  },
  STUDY_IP_INFO_EDIT_TITLE_IP_INFO: {
    ko: "IP 정보 수정",
    en: "Edit IP Info",
  },
  STUDY_IP_INFO_EDIT_SUCC: {
    ko: "IP 정보를 수정했습니다.",
    en: "You have edited IP info successfully.",
  },
  STUDY_IP_INFO_EDIT_FAIL: {
    ko: "IP 정보 수정에 실패했습니다. ({message})",
    en: "You have failed to edit IP info. ({message})",
  },
};

export const STUDY_IP_DELIVERY_LANG: {
  STUDY_IP_DELEVERY_BREADCRUMB: MvnCodeFE;
  STUDY_IP_DELIVERY_LIST_BTN_REQUEST: MvnCodeFE;
  STUDY_IP_DELIVERY_LIST_STATUS_RADIO_ITEM_LABEL_AUTO_REQUEST_ACCEPT: MvnCodeFE;
  STUDY_IP_DELIVERY_LIST_STATUS_RADIO_ITEM_LABEL_CANCEL: MvnCodeFE;
  STUDY_IP_DELIVERY_LIST_STATUS_RADIO_ITEM_LABEL_APPROVE: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_TITLE: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_LABEL_SITE: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_LABEL_INVESTIGATIONAL_PRODUCT: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_LABEL_IP_CATEGORY: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_LABEL_AMOUNT: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_LABEL_EXPECTED_IP_DELIVERY_DATE: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_LABEL_COMMENT: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_SITE_PLACEHOLDER: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_IP_INFO_PLACEHOLDER: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_IP_CATEGORY_PLACEHOLDER: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_AMOUNT_UNDER_ZERO_ERROR_MESSAGE: MvnCodeFE;
  STUDY_IP_DELIVERY_APPROVE_AUTO_REQUEST_DIALOG_TITLE: MvnCodeFE;
  STUDY_IP_DELIVERY_CANCEL_REQUEST_DIALOG_TITLE: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_APPROVE_DIALOG_TITLE: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_DIALOG_TITLE: MvnCodeFE;
  STUDY_IP_DELIVERY_START_CONFIRM_DIALOG_TITLE: MvnCodeFE;
  STUDY_IP_DELIVERY_START_CONFIRM_FAIL: MvnCodeFE;
  STUDY_IP_DELIVERY_CONFIRM_DIALOG_TITLE: MvnCodeFE;
  STUDY_IP_DELIVERY_START_CONFIRM_TITLE_REQUEST_INFORMATION: MvnCodeFE;
  STUDY_IP_DELIVERY_START_CONFIRM_TITLE_DETAIL_INFORMATION: MvnCodeFE;
  STUDY_IP_DELIVERY_START_CONFIRM_LABEL_SITE: MvnCodeFE;
  STUDY_IP_DELIVERY_START_CONFIRM_LABEL_REQUEST_DATE: MvnCodeFE;
  STUDY_IP_DELIVERY_START_CONFIRM_LABEL_EXPECTED_DELIVERED_DATE: MvnCodeFE;
  STUDY_IP_DELIVERY_START_CONFIRM_LABEL_REQUEST_COMMENT: MvnCodeFE;
  STUDY_IP_DELIVERY_START_CONFIRM_LABEL_START_DATE: MvnCodeFE;
  STUDY_IP_DELIVERY_START_CONFIRM_LABEL_DETAIL_INFO_PER_IP_CODE: MvnCodeFE;
  STUDY_IP_DELIVERY_START_CONFIRM_CHECKBOX_LABEL_FILL_EQUAL_VALUE: MvnCodeFE;
  STUDY_IP_DELIVERY_CONFIRM_TITLE_DELIVERY_INFORMATION: MvnCodeFE;
  STUDY_IP_DELIVERY_CONFIRM_TITLE_DELIVERY_ERROR: MvnCodeFE;
  STUDY_IP_DELIVERY_CONFIRM_LABEL_SITE: MvnCodeFE;
  STUDY_IP_DELIVERY_CONFIRM_LABEL_COMPLETE_DATE: MvnCodeFE;
  STUDY_IP_DELIVERY_CONFIRM_LABEL_DELIVERY_IP: MvnCodeFE;
  STUDY_IP_DELIVERY_CONFIRM_LABEL_COMMENT: MvnCodeFE;
  // Action에 대한 msg
  STUDY_IP_DELIVERY_REQUEST_DOCUMENT_DOWNLOAD_SUCC: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_DOCUMENT_DOWNLOAD_FAIL: MvnCodeFE;
  STUDY_IP_DELIVERY_CONFIRM_DOCUMENT_DOWNLOAD_SUCC: MvnCodeFE;
  STUDY_IP_DELIVERY_CONFIRM_DOCUMENT_DOWNLOAD_FAIL: MvnCodeFE;
  STUDY_IP_DELIVERY_AUTO_REQUEST_APPROVE_SUCC: MvnCodeFE;
  STUDY_IP_DELIVERY_AUTO_REQUEST_APPROVE_FAIL: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_CANCEL_SUCC: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_CANCEL_FAIL: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_APPROVE_SUCC: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_APPROVE_FAIL: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_SUCC: MvnCodeFE;
  STUDY_IP_DELIVERY_REQUEST_FAIL: MvnCodeFE;
  STUDY_IP_DELIVERY_START_CONFIRM_SUCC: MvnCodeFE;
  STUDY_IP_DELIVERY_CONFIRM_SUCC: MvnCodeFE;
  STUDY_IP_DELIVERY_CONFIRM_FAIL: MvnCodeFE;
} = {
  STUDY_IP_DELEVERY_BREADCRUMB: {
    ko: "IP 배송 상태를 관리하고, 관련 문서를 출력할 수 있습니다.",
    en: "Manage IP delivery status and export relevant documents.",
  },
  STUDY_IP_DELIVERY_LIST_BTN_REQUEST: {
    ko: "배송 요청",
    en: "Delivery Request",
  },
  STUDY_IP_DELIVERY_LIST_STATUS_RADIO_ITEM_LABEL_AUTO_REQUEST_ACCEPT: {
    ko: "자동 요청 승인",
    en: "Approve automatic order",
  },
  STUDY_IP_DELIVERY_LIST_STATUS_RADIO_ITEM_LABEL_CANCEL: {
    ko: "요청 취소",
    en: "Cancel order",
  },
  STUDY_IP_DELIVERY_LIST_STATUS_RADIO_ITEM_LABEL_APPROVE: {
    ko: "요청 승인",
    en: "Approve order",
  },
  STUDY_IP_DELIVERY_REQUEST_TITLE: {
    ko: "배송 요청",
    en: "Delivery Request",
  },
  STUDY_IP_DELIVERY_REQUEST_LABEL_SITE: {
    ko: "실시기관",
    en: "Site",
  },
  STUDY_IP_DELIVERY_REQUEST_LABEL_INVESTIGATIONAL_PRODUCT: {
    ko: "Investigational Product",
    en: "Investigational Product",
  },
  STUDY_IP_DELIVERY_REQUEST_LABEL_IP_CATEGORY: {
    ko: "Category",
    en: "Category",
  },
  STUDY_IP_DELIVERY_REQUEST_LABEL_AMOUNT: {
    ko: "수량",
    en: "Amount",
  },
  STUDY_IP_DELIVERY_REQUEST_LABEL_EXPECTED_IP_DELIVERY_DATE: {
    ko: "기대 수령 날짜",
    en: "Expected date of delivery",
  },
  STUDY_IP_DELIVERY_REQUEST_LABEL_COMMENT: {
    ko: "메모",
    en: "Comment",
  },
  STUDY_IP_DELIVERY_REQUEST_SITE_PLACEHOLDER: {
    ko: "실시기관을 선택해 주세요.",
    en: "Please select a site.",
  },
  STUDY_IP_DELIVERY_REQUEST_IP_INFO_PLACEHOLDER: {
    ko: "IP Name (No)",
    en: "IP Name (No)",
  },
  STUDY_IP_DELIVERY_REQUEST_IP_CATEGORY_PLACEHOLDER: {
    ko: "IP Category",
    en: "IP Category",
  },
  STUDY_IP_DELIVERY_REQUEST_AMOUNT_UNDER_ZERO_ERROR_MESSAGE: {
    ko: "0보다 큰 정수를 입력해 주세요.",
    en: "Please enter a number greater than 0.",
  },
  STUDY_IP_DELIVERY_APPROVE_AUTO_REQUEST_DIALOG_TITLE: {
    ko: "IP 자동 배송 요청 승인을 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to approve IP automatic order.",
  },
  STUDY_IP_DELIVERY_CANCEL_REQUEST_DIALOG_TITLE: {
    ko: "IP 배송 요청 취소를 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to cancel IP automatic order.",
  },
  STUDY_IP_DELIVERY_REQUEST_APPROVE_DIALOG_TITLE: {
    ko: "IP 배송 요청 승인을 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to approve IP delivery request.",
  },
  STUDY_IP_DELIVERY_REQUEST_DIALOG_TITLE: {
    ko: "IP 배송 요청을 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to request IP delivery.",
  },
  STUDY_IP_DELIVERY_START_CONFIRM_DIALOG_TITLE: {
    ko: "IP 배송 시작을 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to start delivery.",
  },
  STUDY_IP_DELIVERY_CONFIRM_DIALOG_TITLE: {
    ko: "IP 배송 완료 확인을 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to confirm IP delivery completion.",
  },
  STUDY_IP_DELIVERY_START_CONFIRM_TITLE_REQUEST_INFORMATION: {
    ko: "배송 요청 정보",
    en: "Delivery Request Information",
  },
  STUDY_IP_DELIVERY_START_CONFIRM_TITLE_DETAIL_INFORMATION: {
    ko: "배송 상세 정보",
    en: "Delivery Detail Information",
  },
  STUDY_IP_DELIVERY_START_CONFIRM_LABEL_SITE: {
    ko: "실시기관명 (실시기관 코드)",
    en: "Site Name (Site Code)",
  },
  STUDY_IP_DELIVERY_START_CONFIRM_LABEL_REQUEST_DATE: {
    ko: "요청 날짜",
    en: "Request date",
  },
  STUDY_IP_DELIVERY_START_CONFIRM_LABEL_EXPECTED_DELIVERED_DATE: {
    ko: "기대 수령 날짜",
    en: "Expected date of delivery",
  },
  STUDY_IP_DELIVERY_START_CONFIRM_LABEL_REQUEST_COMMENT: {
    ko: "요청 메모",
    en: "Request Comment",
  },
  STUDY_IP_DELIVERY_START_CONFIRM_LABEL_START_DATE: {
    ko: "배송 시작 일자",
    en: "Delivery start date",
  },
  STUDY_IP_DELIVERY_START_CONFIRM_LABEL_DETAIL_INFO_PER_IP_CODE: {
    ko: "IP Code 별 Lot No. 및 Expired Date 정보",
    en: "IP code Lot No. & Expiration date",
  },
  STUDY_IP_DELIVERY_START_CONFIRM_CHECKBOX_LABEL_FILL_EQUAL_VALUE: {
    ko: "동일 값 채우기",
    en: "Paste all",
  },
  STUDY_IP_DELIVERY_CONFIRM_TITLE_DELIVERY_INFORMATION: {
    ko: "IP 배송 정보",
    en: "IP Delivery Information",
  },
  STUDY_IP_DELIVERY_CONFIRM_TITLE_DELIVERY_ERROR: {
    ko: "IP 배송 오류",
    en: "IP Delivery Error",
  },
  STUDY_IP_DELIVERY_CONFIRM_LABEL_SITE: {
    ko: "실시기관명 (실시기관 코드)",
    en: "Site Name (Site Code)",
  },
  STUDY_IP_DELIVERY_CONFIRM_LABEL_COMPLETE_DATE: {
    ko: "배송 완료일",
    en: "Delivery completion date",
  },
  STUDY_IP_DELIVERY_CONFIRM_LABEL_DELIVERY_IP: {
    ko: "배송 IP",
    en: "Delivery IP",
  },
  STUDY_IP_DELIVERY_CONFIRM_LABEL_COMMENT: {
    ko: "인수 메모",
    en: "Confirm Comment",
  },
  //
  STUDY_IP_DELIVERY_REQUEST_DOCUMENT_DOWNLOAD_SUCC: {
    ko: "출하요청서 다운로드에 성공했습니다.",
    en: "Delivery request has been downloaded successfully.",
  },
  STUDY_IP_DELIVERY_REQUEST_DOCUMENT_DOWNLOAD_FAIL: {
    ko: "출하요청서 다운로드에 실패했습니다. ({message}).",
    en: "Failed to download delivery request. ({message})",
  },
  STUDY_IP_DELIVERY_CONFIRM_DOCUMENT_DOWNLOAD_SUCC: {
    ko: "인수확인서 다운로드에 성공했습니다.",
    en: "Delivery confirmation has been downloaded successfully.",
  },
  STUDY_IP_DELIVERY_CONFIRM_DOCUMENT_DOWNLOAD_FAIL: {
    ko: "인수확인서 다운로드에 실패했습니다. ({message})",
    en: "Failed to download delivery confirmation. ({message})",
  },
  STUDY_IP_DELIVERY_AUTO_REQUEST_APPROVE_SUCC: {
    ko: "자동 배송 요청을 승인했습니다.",
    en: "Automatic order has been approved successfully.",
  },
  STUDY_IP_DELIVERY_AUTO_REQUEST_APPROVE_FAIL: {
    ko: "자동 배송 요청 승인에 실패하였습니다. ({message})",
    en: "Failed to approve automatic order. ({message})",
  },
  STUDY_IP_DELIVERY_REQUEST_CANCEL_SUCC: {
    ko: "배송 요청을 취소했습니다.",
    en: "Delivery request has been canceled successfully.",
  },
  STUDY_IP_DELIVERY_REQUEST_CANCEL_FAIL: {
    ko: "요청 취소에 실패하였습니다. ({message})",
    en: "Failed to cancel delivery request. ({message})",
  },
  STUDY_IP_DELIVERY_REQUEST_APPROVE_SUCC: {
    ko: "배송 요청을 수락했습니다",
    en: "Delivery request has been approved successfully.",
  },
  STUDY_IP_DELIVERY_REQUEST_APPROVE_FAIL: {
    ko: "요청 수락에 실패하였습니다. ({message})",
    en: "Failed to approve delivery request. ({message})",
  },
  STUDY_IP_DELIVERY_REQUEST_SUCC: {
    ko: "IP 배송을 요청하였습니다.",
    en: "IP delivery has been requested.",
  },
  STUDY_IP_DELIVERY_REQUEST_FAIL: {
    ko: "IP 배송 요청에 실패하였습니다. ({message})",
    en: "Failed to request IP delivery. ({message})",
  },
  STUDY_IP_DELIVERY_START_CONFIRM_SUCC: {
    ko: "IP 배송 시작을 확인하였습니다.",
    en: "The departure of IP delivery has been confirmed.",
  },
  STUDY_IP_DELIVERY_START_CONFIRM_FAIL: {
    ko: "IP 배송 시작에 실패하였습니다. ({message})",
    en: "Failed to confirm IP delivery departure. ({message})",
  },
  STUDY_IP_DELIVERY_CONFIRM_SUCC: {
    ko: "IP 배송 완료를 확인했습니다.",
    en: "The completion of IP delivery has been confirmed.",
  },
  STUDY_IP_DELIVERY_CONFIRM_FAIL: {
    ko: "IP 배송 완료 확인에 실패하였습니다. ({message})",
    en: "Failed to confirm IP delivery completion. ({message})",
  },
};

export const STUDY_IP_AUTO_CONDITION_LANG: {
  STUDY_IP_AUTO_CONDITION_LIST_BTN_ADD: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_ADD_TITLE: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_EDIT_TITLE: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_ADD_LABEL_SITE: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_ADD_SITE_PLACEHOLDER: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_ADD_LABEL_INVESTIGATIONAL_PRODUCT: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_ADD_INVESTIGATIONAL_PRODUCT_PLACEHOLDER: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_ADD_LABEL_CONDITION_COUNT: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_ADD_LABEL_REQUEST_COUNT: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_ADD_LABEL_REQUEST_MULTIPLIER: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_ADD_LABEL_EXPECTED_DELIVERY_DAYS: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_ADD_LABEL_COMMENT: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_EDIT_LABEL_SITE: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_EDIT_LABEL_INVESTIGATIONAL_PRODUCT: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_EDIT_LABEL_CONDITION_COUNT: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_EDIT_LABEL_REQUEST_COUNT: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_EDIT_LABEL_EXPECTED_DELIVERY_DAYS: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_EDIT_LABEL_COMMENT: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_ACTIVE_SUCC: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_ACTIVE_FAIL: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_INACTIVE_SUCC: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_INACTIVE_FAIL: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_ADD_SUCC: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_ADD_FAIL: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_EDIT_SUCC: MvnCodeFE;
  STUDY_IP_AUTO_CONDITION_EDIT_FAIL: MvnCodeFE;
} = {
  STUDY_IP_AUTO_CONDITION_LIST_BTN_ADD: {
    ko: "Auto Condition 추가",
    en: "Add Auto Condition",
  },
  STUDY_IP_AUTO_CONDITION_ADD_TITLE: {
    ko: "Auto Condition 추가",
    en: "Add Auto Condition",
  },
  STUDY_IP_AUTO_CONDITION_EDIT_TITLE: {
    ko: "Auto Condition 수정",
    en: "Edit Auto Condition",
  },
  STUDY_IP_AUTO_CONDITION_ADD_LABEL_SITE: {
    ko: "실시기관",
    en: "Site",
  },
  STUDY_IP_AUTO_CONDITION_ADD_SITE_PLACEHOLDER: {
    ko: "실시기관을 선택해 주세요.",
    en: "Please select a site.",
  },
  STUDY_IP_AUTO_CONDITION_ADD_LABEL_INVESTIGATIONAL_PRODUCT: {
    ko: "Investigational Product",
    en: "Investigational Product",
  },
  STUDY_IP_AUTO_CONDITION_ADD_INVESTIGATIONAL_PRODUCT_PLACEHOLDER: {
    ko: "Investigational Product",
    en: "Investigational Product",
  },
  STUDY_IP_AUTO_CONDITION_ADD_LABEL_CONDITION_COUNT: {
    ko: "임계수량",
    en: "Threshold Value",
  },
  STUDY_IP_AUTO_CONDITION_ADD_LABEL_REQUEST_COUNT: {
    ko: "요청수량",
    en: "Request Quantity",
  },
  STUDY_IP_AUTO_CONDITION_ADD_LABEL_REQUEST_MULTIPLIER: {
    ko: "요청배수",
    en: "Request Multiplier",
  },
  STUDY_IP_AUTO_CONDITION_ADD_LABEL_EXPECTED_DELIVERY_DAYS: {
    ko: "예상 배송일수",
    en: "Expected delivery time (days)",
  },
  STUDY_IP_AUTO_CONDITION_ADD_LABEL_COMMENT: {
    ko: "메모",
    en: "Memo",
  },
  STUDY_IP_AUTO_CONDITION_EDIT_LABEL_SITE: {
    ko: "실시기관",
    en: "Site",
  },
  STUDY_IP_AUTO_CONDITION_EDIT_LABEL_INVESTIGATIONAL_PRODUCT: {
    ko: "Investigational Product",
    en: "Investigational Product",
  },
  STUDY_IP_AUTO_CONDITION_EDIT_LABEL_CONDITION_COUNT: {
    ko: "임계수량",
    en: "Threshold Value",
  },
  STUDY_IP_AUTO_CONDITION_EDIT_LABEL_REQUEST_COUNT: {
    ko: "요청수량",
    en: "Request Quantity",
  },
  STUDY_IP_AUTO_CONDITION_EDIT_LABEL_EXPECTED_DELIVERY_DAYS: {
    ko: "예상 배송일수",
    en: "Estimated IP delivery period",
  },
  STUDY_IP_AUTO_CONDITION_EDIT_LABEL_COMMENT: {
    ko: "메모",
    en: "Memo",
  },
  STUDY_IP_AUTO_CONDITION_ACTIVE_SUCC: {
    ko: "성공적으로 IP Auto Condition 을 활성화하였습니다.",
    en: "IP Auto Condition has been enabled successfully.",
  },
  STUDY_IP_AUTO_CONDITION_ACTIVE_FAIL: {
    ko: "IP Auto Condition 활성화에 실패하였습니다. ({message})",
    en: "Failed to enable IP Auto Condition. ({message})",
  },
  STUDY_IP_AUTO_CONDITION_INACTIVE_SUCC: {
    ko: "성공적으로 IP Auto Condition 을 비활성화하였습니다.",
    en: "IP Auto Condition has been disabled successfully.",
  },
  STUDY_IP_AUTO_CONDITION_INACTIVE_FAIL: {
    ko: "IP Auto Condition 비활성화에 실패하였습니다. ({message})",
    en: "Failed to disable IP Auto Condition. ({message})",
  },
  STUDY_IP_AUTO_CONDITION_ADD_SUCC: {
    ko: "IP Auto Condition 등록에 성공하였습니다.",
    en: "IP Auto Condition has been registered successfully.",
  },
  STUDY_IP_AUTO_CONDITION_ADD_FAIL: {
    ko: "IP Auto Condition 등록에 실패하였습니다. ({message})",
    en: "Failed to register IP Auto Condition. ({message})",
  },
  STUDY_IP_AUTO_CONDITION_EDIT_SUCC: {
    ko: "IP Auto Condition 수정에 성공하였습니다.",
    en: "IP Auto Condition has been edited successfully.",
  },
  STUDY_IP_AUTO_CONDITION_EDIT_FAIL: {
    ko: "IP Auto Condition 수정에 실패하였습니다. ({message})",
    en: "Failed to edit IP Auto Condition. ({message})",
  },
};

export const STUDY_IP_STOCK_LANG: {
  STUDY_IP_STOCK_STATUS_AVAILABLE_LABEL: MvnCodeFE;
  STUDY_IP_STOCK_LIST_BTN_IP_ERROR: MvnCodeFE;
  STUDY_IP_STOCK_LIST_BTN_STATUS_CHANGE: MvnCodeFE;
  STUDY_IP_STOCK_LIST_BTN_IP_ERROR_CLICK_NOTI_DIFFERENT_SITE: MvnCodeFE;
  STUDY_IP_STOCK_LIST_BTN_STATUS_CHANGE_CLICK_NOTI_DIFFERENT_SITE: MvnCodeFE;
  STUDY_IP_STOCK_CHANGE_AVAILABLE_IP_STATUS_TITLE: MvnCodeFE;
  STUDY_IP_STOCK_CHANGE_AVAILABLE_IP_STATUS_LABEL_SITE_NAME: MvnCodeFE;
  STUDY_IP_STOCK_CHANGE_AVAILABLE_IP_STATUS_LABEL_STATUS: MvnCodeFE;
  STUDY_IP_STOCK_CHANGE_AVAILABLE_IP_STATUS_LABEL_IP_CODE: MvnCodeFE;
  STUDY_IP_STOCK_CHANGE_AVAILABLE_IP_STATUS_LABEL_ERROR_COMMENT: MvnCodeFE;
  STUDY_IP_STOCK_EDIT_DISABLED_IP_STATUS_TITLE: MvnCodeFE;
  STUDY_IP_STOCK_EDIT_DISABLED_IP_STATUS_LABEL_SITE_NAME: MvnCodeFE;
  STUDY_IP_STOCK_EDIT_DISABLED_IP_STATUS_LABEL_STATUS: MvnCodeFE;
  STUDY_IP_STOCK_EDIT_DISABLED_IP_STATUS_LABEL_IP_CODE: MvnCodeFE;
  STUDY_IP_STOCK_EDIT_DISABLED_IP_STATUS_LABEL_ERROR_COMMENT: MvnCodeFE;
  STUDY_IP_STOCK_AVAILABLE_IP_STATUS_TO_ERROR_SUCC: MvnCodeFE;
  STUDY_IP_STOCK_AVAILABLE_IP_STATUS_TO_ERROR_FAIL: MvnCodeFE;
  STUDY_IP_STOCK_AVAILABLE_IP_STATUS_TO_ERROR_DIALOG_TITLE: MvnCodeFE;
  STUDY_IP_STOCK_DISABLED_IP_STATUS_EDIT_SUCC: MvnCodeFE;
  STUDY_IP_STOCK_DISABLED_IP_STATUS_EDIT_FAIL: MvnCodeFE;
  STUDY_IP_STOCK_DISABLED_IP_STATUS_EDIT_DIALOG_TITLE: MvnCodeFE;
} = {
  STUDY_IP_STOCK_STATUS_AVAILABLE_LABEL: {
    ko: "사용 가능 재고",
    en: "Available Stocks",
  },
  STUDY_IP_STOCK_LIST_BTN_IP_ERROR: {
    ko: "IP 오류",
    en: "IP Error",
  },
  STUDY_IP_STOCK_LIST_BTN_STATUS_CHANGE: {
    ko: "상태 변경",
    en: "Status Change",
  },
  STUDY_IP_STOCK_LIST_BTN_IP_ERROR_CLICK_NOTI_DIFFERENT_SITE: {
    ko: "동일한 기관의 IP만 상태를 변경할 수 있습니다.",
    en: "IP from the same site can be changed its status.",
  },
  STUDY_IP_STOCK_LIST_BTN_STATUS_CHANGE_CLICK_NOTI_DIFFERENT_SITE: {
    ko: "동일한 기관의 IP만 상태 수정이 가능합니다.",
    en: "IP from the same site can be edited its status.",
  },
  STUDY_IP_STOCK_CHANGE_AVAILABLE_IP_STATUS_TITLE: {
    ko: "IP 오류",
    en: "IP Error",
  },
  STUDY_IP_STOCK_CHANGE_AVAILABLE_IP_STATUS_LABEL_SITE_NAME: {
    ko: "실시기관명 (실시기관 코드)",
    en: "Site Name (Site Code)",
  },
  STUDY_IP_STOCK_CHANGE_AVAILABLE_IP_STATUS_LABEL_STATUS: {
    ko: "상태",
    en: "Status",
  },
  STUDY_IP_STOCK_CHANGE_AVAILABLE_IP_STATUS_LABEL_IP_CODE: {
    ko: "IP Code [Lot No.]",
    en: "IP Code [Lot No.]",
  },
  STUDY_IP_STOCK_CHANGE_AVAILABLE_IP_STATUS_LABEL_ERROR_COMMENT: {
    ko: "오류 메모",
    en: "Error Comment",
  },
  STUDY_IP_STOCK_EDIT_DISABLED_IP_STATUS_TITLE: {
    ko: "IP 오류 수정",
    en: "Edit IP Error",
  },
  STUDY_IP_STOCK_EDIT_DISABLED_IP_STATUS_LABEL_SITE_NAME: {
    ko: "실시기관명 (실시기관 코드)",
    en: "Site Name (Site Code)",
  },
  STUDY_IP_STOCK_EDIT_DISABLED_IP_STATUS_LABEL_STATUS: {
    ko: "상태",
    en: "Status",
  },
  STUDY_IP_STOCK_EDIT_DISABLED_IP_STATUS_LABEL_IP_CODE: {
    ko: "IP Code [Lot No.]",
    en: "IP Code [Lot No.]",
  },
  STUDY_IP_STOCK_EDIT_DISABLED_IP_STATUS_LABEL_ERROR_COMMENT: {
    ko: "메모",
    en: "Comment",
  },
  STUDY_IP_STOCK_AVAILABLE_IP_STATUS_TO_ERROR_SUCC: {
    ko: "IP 상태 변경에 성공했습니다.",
    en: "You have chagned IP status successfully.",
  },
  STUDY_IP_STOCK_AVAILABLE_IP_STATUS_TO_ERROR_FAIL: {
    ko: "IP 상태 변경에 실패하였습니다. ({message})",
    en: "Failed to change IP status. ({message})",
  },
  STUDY_IP_STOCK_AVAILABLE_IP_STATUS_TO_ERROR_DIALOG_TITLE: {
    ko: "IP 오류 처리를 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to change IP status.",
  },
  STUDY_IP_STOCK_DISABLED_IP_STATUS_EDIT_SUCC: {
    ko: "IP 오류 수정에 성공했습니다.",
    en: "IP status has been edited successfully.",
  },
  STUDY_IP_STOCK_DISABLED_IP_STATUS_EDIT_FAIL: {
    ko: "IP 오류 수정에 실패하였습니다. ({message})",
    en: "Failed to edit IP status. ({message})",
  },
  STUDY_IP_STOCK_DISABLED_IP_STATUS_EDIT_DIALOG_TITLE: {
    ko: "IP 오류 수정를 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to edit IP status.",
  },
};

export const STUDY_IP_RETURN_LANG: {
  STUDY_IP_RETURN_LIST_BTN_REQUEST: MvnCodeFE;
  STUDY_IP_RETURN_LIST_STATUS_RADIO_ITEM_LABEL_CONFIRM: MvnCodeFE;
  STUDY_IP_RETURN_LIST_STATUS_RADIO_ITEM_LABEL_CANCEL: MvnCodeFE;
  STUDY_IP_RETURN_REQUEST_UNUSED_IP_RETURN_REQUEST_TITLE: MvnCodeFE;
  STUDY_IP_RETURN_REQUEST_USED_IP_RETURN_REQUEST_TITLE: MvnCodeFE;
  STUDY_IP_RETURN_REQUEST_LABEL_STIE: MvnCodeFE;
  STUDY_IP_RETURN_REQUEST_LABEL_EXPECTED_RETURN_DATE: MvnCodeFE;
  STUDY_IP_RETURN_REQUEST_LABEL_COMMENT: MvnCodeFE;
  STUDY_IP_RETURN_REQUEST_LABEL_IP_CODE_OF_RETURN_REQUEST: MvnCodeFE;
  STUDY_IP_RETURN_CONFIRM_UNUSED_IP_RETURN_CONFIRM_TITLE: MvnCodeFE;
  STUDY_IP_RETURN_CONFIRM_USED_IP_RETURN_CONFIRM_TITLE: MvnCodeFE;
  STUDY_IP_RETURN_CONFIRM_LABEL_STIE: MvnCodeFE;
  STUDY_IP_RETURN_CONFIRM_LABEL_RETURNED_DATE: MvnCodeFE;
  STUDY_IP_RETURN_CONFIRM_LABEL_COMMENT: MvnCodeFE;
  STUDY_IP_RETURN_CONFIRM_LABEL_IP_CODE_OF_RETURN_REQUEST: MvnCodeFE;
  STUDY_IP_RETURN_REQUEST_CANCEL_SUCC: MvnCodeFE;
  STUDY_IP_RETURN_REQUEST_CANCEL_FAIL: MvnCodeFE;
  STUDY_IP_RETURN_REQUEST_CANCEL_DIALOG_TITLE: MvnCodeFE;
  STUDY_IP_RETURN_REQUEST_SUCC: MvnCodeFE;
  STUDY_IP_RETURN_REQUEST_FAIL: MvnCodeFE;
  STUDY_IP_RETURN_REQUEST_DIALOG_TITLE: MvnCodeFE;
  STUDY_IP_RETURN_CONFIRM_SUCC: MvnCodeFE;
  STUDY_IP_RETURN_CONFIRM_FAIL: MvnCodeFE;
  STUDY_IP_RETURN_CONFIRM_DIALOG_TITLE: MvnCodeFE;
  STUDY_IP_RETURN_CONFIRM_DOCUMENT_DOWNLOAD_SUCC: MvnCodeFE;
  STUDY_IP_RETURN_CONFIRM_DOCUMENT_DOWNLOAD_FAIL: MvnCodeFE;
} = {
  STUDY_IP_RETURN_LIST_BTN_REQUEST: {
    ko: "반납 요청",
    en: "IP Return Request",
  },
  STUDY_IP_RETURN_LIST_STATUS_RADIO_ITEM_LABEL_CONFIRM: {
    ko: "반납 요청 확인",
    en: "Confirm return request",
  },
  STUDY_IP_RETURN_LIST_STATUS_RADIO_ITEM_LABEL_CANCEL: {
    ko: "반납 요청 취소",
    en: "Cancel return request",
  },
  STUDY_IP_RETURN_REQUEST_UNUSED_IP_RETURN_REQUEST_TITLE: {
    ko: "사용하지 않은 IP 반납 요청",
    en: "Request unused IP return",
  },
  STUDY_IP_RETURN_REQUEST_USED_IP_RETURN_REQUEST_TITLE: {
    ko: "사용된 IP 반납 요청",
    en: "Request used IP return",
  },
  STUDY_IP_RETURN_REQUEST_LABEL_STIE: {
    ko: "실시기관",
    en: "Site",
  },
  STUDY_IP_RETURN_REQUEST_LABEL_EXPECTED_RETURN_DATE: {
    ko: "기대 반납 일자",
    en: "Expected Return Date",
  },
  STUDY_IP_RETURN_REQUEST_LABEL_COMMENT: {
    ko: "요청 메모",
    en: "Request Comment",
  },
  STUDY_IP_RETURN_REQUEST_LABEL_IP_CODE_OF_RETURN_REQUEST: {
    ko: "IP Code [Lot No.]",
    en: "IP Code [Lot No.]",
  },
  STUDY_IP_RETURN_CONFIRM_UNUSED_IP_RETURN_CONFIRM_TITLE: {
    ko: "사용하지 않은 IP 반납 확인",
    en: "Confirm unused IP return",
  },
  STUDY_IP_RETURN_CONFIRM_USED_IP_RETURN_CONFIRM_TITLE: {
    ko: "사용된 IP 반납 확인",
    en: "Confirm used IP return",
  },
  STUDY_IP_RETURN_CONFIRM_LABEL_STIE: {
    ko: "실시기관",
    en: "Site",
  },
  STUDY_IP_RETURN_CONFIRM_LABEL_RETURNED_DATE: {
    ko: "반납 일자",
    en: "Returned Date",
  },
  STUDY_IP_RETURN_CONFIRM_LABEL_COMMENT: {
    ko: "확인 메모",
    en: "Confirm Comment",
  },
  STUDY_IP_RETURN_CONFIRM_LABEL_IP_CODE_OF_RETURN_REQUEST: {
    ko: "IP Code [Lot No.]",
    en: "IP Code [Lot No.]",
  },
  STUDY_IP_RETURN_REQUEST_CANCEL_SUCC: {
    ko: "반납 요청을 취소했습니다.",
    en: "Return request has been canceled successfully.",
  },
  STUDY_IP_RETURN_REQUEST_CANCEL_FAIL: {
    ko: "반납 요청 취소에 실패하였습니다. ({message})",
    en: "Failed to cancel return request. ({message})",
  },
  STUDY_IP_RETURN_REQUEST_CANCEL_DIALOG_TITLE: {
    ko: "IP 반납 요청 취소를 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to cancel IP return request.",
  },
  STUDY_IP_RETURN_REQUEST_SUCC: {
    ko: "반납 요청에 성공했습니다.",
    en: "Return request has been completed successfully.",
  },
  STUDY_IP_RETURN_REQUEST_FAIL: {
    ko: "반납 요청에 실패했습니다. ({message})",
    en: "Failed to complete return request. ({message})",
  },
  STUDY_IP_RETURN_REQUEST_DIALOG_TITLE: {
    ko: "IP 반납 요청을 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to request IP return.",
  },
  STUDY_IP_RETURN_CONFIRM_SUCC: {
    ko: "IP 반납을 확인했습니다.",
    en: "IP return has been confirmed successfully.",
  },
  STUDY_IP_RETURN_CONFIRM_FAIL: {
    ko: "IP 반납 확인을 실패하였습니다. ({message})",
    en: "Failed to confirm IP return. ({message})",
  },
  STUDY_IP_RETURN_CONFIRM_DIALOG_TITLE: {
    ko: "IP 반납 확인을 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to confirm IP return.",
  },
  STUDY_IP_RETURN_CONFIRM_DOCUMENT_DOWNLOAD_SUCC: {
    ko: "반납확인서 다운로드에 성공했습니다.",
    en: "Return confirmation has been downloaded successfully.",
  },
  STUDY_IP_RETURN_CONFIRM_DOCUMENT_DOWNLOAD_FAIL: {
    ko: "반납확인서 다운로드에 실패했습니다. ({message})",
    en: "Failed to download return confirmation. ({message})",
  },
};

export const STUDY_IP_ACCOUNTABILITY_LANG: {
  STUDY_IP_ACCOUNTABILITY_BY_SITE: MvnCodeFE;
  STUDY_IP_ACCOUNTABILITY_BY_SUBJECT: MvnCodeFE;
} = {
  STUDY_IP_ACCOUNTABILITY_BY_SITE: {
    ko: "임상시험용 의약품 기관단위 수불기록",
    en: "IP Accountability by Site",
  },
  STUDY_IP_ACCOUNTABILITY_BY_SUBJECT: {
    ko: "임상시험용 의약품 대상자단위 수불기록",
    en: "IP Accountability by Subject",
  },
};

MvnCodeInitiate(STUDY_IP_DELIVERY_LANG);
MvnCodeInitiate(STUDY_IP_INFO_LANG);
MvnCodeInitiate(STUDY_IP_AUTO_CONDITION_LANG);
MvnCodeInitiate(STUDY_IP_STOCK_LANG);
MvnCodeInitiate(STUDY_IP_RETURN_LANG);
MvnCodeInitiate(STUDY_IP_ACCOUNTABILITY_LANG);
