import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const AUTH_ROLE_CODE: {
  AUTH_ROLE_MODIFY_INVALID_NAME_DUPLICATED: MvnCodeBE;
} = {
  AUTH_ROLE_MODIFY_INVALID_NAME_DUPLICATED: {
    status: 400,
    ko: "해당 이름은 사용중입니다. 다른 이름을 사용해 주세요.",
    en: "There is a role with same name. Please use the other name.",
  },
};

MvnCodeInitiate(AUTH_ROLE_CODE);
