export interface BaseHeight {
  appHeaderHeight: string;
  appFooterHeight: string;
  appSidebarIconVisibEffectSize: string;
  appSidebarNaviPaddingLeft: string;
  appSidebarNaviIconWidth: string;
  appSidebarNaviNavHeight: string;
}

export const baseHeight: BaseHeight = {
  appHeaderHeight: "56px",
  appFooterHeight: "45px",
  appSidebarIconVisibEffectSize: "0.5rem",
  appSidebarNaviPaddingLeft: "1rem",
  appSidebarNaviIconWidth: "2.8rem",
  appSidebarNaviNavHeight: "2.57rem",
};
