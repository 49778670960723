import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_CASE_SYNC_CODE: {
  CASE_SYNC_INVALID_ALREADY_SYNCED: MvnCodeBE;
  CASE_SYNC_INVALID_NOT_SUPPORTED_DATA: MvnCodeBE;
  CASE_SYNC_INVALID_NOT_SUPPORTED_DICTIONARY_TYPE: MvnCodeBE;
  CASE_SYNC_INVALID_NOT_SUPPORTED_DICTIONARY_VERSION: MvnCodeBE;
  CASE_SYNC_INVALID_DICTIONARY_VERSION_DUPLICATE: MvnCodeBE;
  CASE_SYNC_INVALID_DICTIONARY_VALUE_NOT_EXIST: MvnCodeBE;
  CASE_SYNC_INVALID_DICTIONARY_VALUE: MvnCodeBE;
  CASE_SYNC_INVALID_DIFFERENT_STUDY_SUBJECT: MvnCodeBE;
  CASE_SYNC_INVALID_REQUIRED_VALUE_MISSING: MvnCodeBE;
  CASE_SYNC_INVALID_SYNC_BUFFER_DATA: MvnCodeBE;
  CASE_SYNC_NOT_AUTHORIZED: MvnCodeBE;
  CASE_SYNC_INVALID_RESERVED_CRF_ITEM_SPEC_NOT_EXIST: MvnCodeBE;
  CASE_SYNC_INVALID_CASE_IDENTIFIER_MISSING: MvnCodeBE;
  CASE_SYNC_INVALID_SYNC_ERROR: MvnCodeBE;
} = {
  CASE_SYNC_INVALID_ALREADY_SYNCED: {
    status: 400,
    ko: "이미 Case를 생성한 sync Data입니다.",
    en: "Sync Data with a Case already created.",
  },
  CASE_SYNC_INVALID_NOT_SUPPORTED_DATA: {
    status: 400,
    ko: "지원하지 않는 데이터입니다. ({item})",
    en: "This data is not supported. ({item})",
  },
  CASE_SYNC_INVALID_NOT_SUPPORTED_DICTIONARY_TYPE: {
    status: 400,
    ko: "지원하지 않는 dictionary type입니다. (type: {type})",
    en: "This data is not supported dictionary type. (type: {type})",
  },
  CASE_SYNC_INVALID_NOT_SUPPORTED_DICTIONARY_VERSION: {
    status: 400,
    ko: "지원하지 않는 dictionary version입니다. (version: {version})",
    en: "This data is not supported dictionary version. (version: {version})",
  },
  CASE_SYNC_INVALID_DICTIONARY_VERSION_DUPLICATE: {
    status: 400,
    ko: "하나의 dictionary version만 사용 가능합니다. (dictionary: {type})",
    en: "Only one dictionary version is available. (dictionary: {type})",
  },
  CASE_SYNC_INVALID_DICTIONARY_VALUE_NOT_EXIST: {
    status: 400,
    ko: "dictionary value가 존재하지 않습니다. (type: {type})",
    en: "The dictionary value does not exist. (type: {type})",
  },
  CASE_SYNC_INVALID_DICTIONARY_VALUE: {
    status: 400,
    ko: "유효하지 않은 dictioanry value입니다.",
    en: "Invalid dictionary value.",
  },
  CASE_SYNC_INVALID_DIFFERENT_STUDY_SUBJECT: {
    status: 400,
    ko: "study와 subject가 다른 case sync data를 하나의 case로 생성할 수 없습니다.",
    en: "You cannot create a single case for sync data when study and subject are different cases.",
  },
  CASE_SYNC_INVALID_REQUIRED_VALUE_MISSING: {
    status: 400,
    ko: "필수값이 누락되었습니다. (label: {label})",
    en: "Required Item value is missing. (label: {label})",
  },
  CASE_SYNC_INVALID_SYNC_BUFFER_DATA: {
    status: 400,
    ko: "유효하지 않은 sync data입니다.",
    en: "The sync data is invalid.",
  },
  CASE_SYNC_NOT_AUTHORIZED: {
    status: 403,
    ko: "Sync data로 case를 생성할 권한이 없습니다.",
    en: "You don't have permission to create cases with sync data.",
  },
  CASE_SYNC_INVALID_RESERVED_CRF_ITEM_SPEC_NOT_EXIST: {
    status: 400,
    ko: "CRF에 존재하지 않는 sync data item spec입니다.",
    en: "The sync data item spec does not exist in the CRF.",
  },
  CASE_SYNC_INVALID_CASE_IDENTIFIER_MISSING: {
    status: 400,
    ko: "Case identifier을 입력하지 않았습니다.",
    en: "You don't enter a case identifier.",
  },
  CASE_SYNC_INVALID_SYNC_ERROR: {
    status: 400,
    ko: "Case 생성이 실패했습니다. Safety 고객센터에 연락 주시기 바랍니다.",
    en: "Case creation is failed. Please contact to Safety helpdesk.",
  },
};

MvnCodeInitiate(SAFETY_CASE_SYNC_CODE);
