import { createContext, useContext, useMemo } from "react";
import { SafetyUserInfo } from "@maven-rest/safety/User.dto";
import { SafetyWorkspaceMemberType } from "@maven-rest/safety/WorkspaceMember.dto";
import { SafetyPrivilege } from "@maven-rest/safety/WorkspaceRolePrivilege.dto";

interface CurrentUserInfoContextProps {
  currentUserInfo?: SafetyUserInfo | null;
  workspace?: {
    role: {
      id: bigint | null;
      name: string;
    };
    privileges: Array<SafetyPrivilege>;
    memberType: SafetyWorkspaceMemberType | null;
  };
}

interface CurrentUserInfoContextProviderProps {
  currentUserInfo?: SafetyUserInfo | null;
  workspace?: {
    role: {
      id: bigint | null;
      name: string;
    };
    privileges: Array<SafetyPrivilege>;
    memberType: SafetyWorkspaceMemberType;
  };
  children: React.ReactNode;
}

const CurrentUserInfoContext = createContext<CurrentUserInfoContextProps>({
  currentUserInfo: null,
  workspace: {
    role: {
      id: null,
      name: "",
    },
    privileges: [],
    memberType: null,
  },
});

function CurrentUserInfoContextProvider(props: CurrentUserInfoContextProviderProps) {
  const memorizedValue = useMemo(
    () => ({ currentUserInfo: props.currentUserInfo, workspace: props.workspace }),
    [props.currentUserInfo, props.workspace]
  );

  return <CurrentUserInfoContext.Provider value={memorizedValue}>{props.children}</CurrentUserInfoContext.Provider>;
}

function useCurrentUserInfoContext() {
  return useContext(CurrentUserInfoContext);
}

export { CurrentUserInfoContext, CurrentUserInfoContextProvider, useCurrentUserInfoContext };
