import { useDispatch } from "react-redux";
import { RouteProps } from "react-router-dom";
import { FxGuard } from "@coralblack/flax";
import { CurrentUserInfoContextProvider } from "@contexts/CurrentUserInfoContext";
import { Popup } from "@components/popup/Popup";
import { SafetyUserFlax } from "@maven-rest/safety/User.flax";
import { sessionSlice } from "@maven-rest/states/index";

interface MsaFxGuardProps extends RouteProps {}

export function MsaFxGuard({ component: Component }: MsaFxGuardProps) {
  const Comp: any = Component;
  const dispatch = useDispatch();

  return (
    <>
      <FxGuard
        key="static"
        api={{ ...SafetyUserFlax.getMyInfo({}) }}
        loading={() => <div className="app-spinner" />}
        render={(resp) => {
          if (resp) {
            return (
              <>
                <CurrentUserInfoContextProvider currentUserInfo={resp.userInfo}>
                  <Popup />
                  <Comp currentUserInfo={resp.userInfo} />
                </CurrentUserInfoContextProvider>
              </>
            );
          }

          return;
        }}
        error={(err) => {
          if (err?.code === "CREDENTIAL-INVALID") {
            dispatch(sessionSlice.actions.signOut());

            return <>&nbsp;</>;
          }

          return (
            <>
              [{err?.code || "UNKNOWN"}] {err?.message || "An error has occurred."}
            </>
          );
        }}
      />
    </>
  );
}
