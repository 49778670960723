import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const SITE_LANG: {
  SITE_MANAGEMENT_SITE_BREADCRUMB: MvnCodeFE;
  SITE_ADDED: MvnCodeFE;
  SITE_ADD_FAIL: MvnCodeFE;
  SITE_EDIT_TITLE: MvnCodeFE;
  SITE_EDIT_SUCC: MvnCodeFE;
  SITE_EDIT_FAIL: MvnCodeFE;
  IRB_APPROVED_DATE: MvnCodeFE;
  IRB_APPROVED_DATE_PLACEHOLDER: MvnCodeFE;
  SITE_CONTRACT_DATE_LABEL: MvnCodeFE;
  SITE_CONTRACT_DATE_PLACEHOLDER: MvnCodeFE;
  SITE_START_DATE_LABEL: MvnCodeFE;
  SITE_START_DATE_PLACEHOLDER: MvnCodeFE;
  SITE_ADD: MvnCodeFE;
  SITE_LABEL: MvnCodeFE;
  SITE_NAME: MvnCodeFE;
  SITE_DROPDOWN_PLACEHOLDER: MvnCodeFE;
  SITE_NUMBER_OF_ENROLLED_SUBJECTS: MvnCodeFE;
  SITE_NAME_PLACEHOLDER: MvnCodeFE;
  SITE_CODE_PLACEHOLDER: MvnCodeFE;
  ALLOCATION: MvnCodeFE;
  ALLOCATION_DESC: MvnCodeFE;
  ALLOCATION_PLACEHOLDER: MvnCodeFE;
  STUDY_SITE_ADD_SECTION_TITLE_CRF: MvnCodeFE;
  STUDY_SITE_ADD_SECTION_TITLE_PRINCIPAL_INVESTIGATOR: MvnCodeFE;
  STUDY_SITE_ADD_LABEL_PRINCIPAL_INVESTIGATOR: MvnCodeFE;
  STUDY_SITE_ADD_LABEL_PRINCIPAL_INVESTIGATOR_PHONE_NUMBER: MvnCodeFE;
  STUDY_SITE_ADD_LABEL_EFFECTIVE_DATE: MvnCodeFE;
  STUDY_SITE_ADD_LABEL_EFFECTIVE_DATE_TOOLTIP_FOR_ICF_APPROVED_DATE: MvnCodeFE;
  STUDY_SITE_ADD_LABEL_EFFECTIVE_DATE_TOOLTIP_FOR_REGISTERED_DATE: MvnCodeFE;
  STUDY_SITE_ADD_LABEL_CRF: MvnCodeFE;
  STUDY_SITE_MODIFY_SECTION_TITLE_CRF: MvnCodeFE;
  STUDY_SITE_MODIFY_LABEL_EFFECTIVE_DATE: MvnCodeFE;
  STUDY_SITE_MODIFY_LABEL_CRF: MvnCodeFE;
  STUDY_SITE_MODIFY_ALERT_MIGRATABLE_SUBJECT_EXIST_001: MvnCodeFE;
  STUDY_SITE_MODIFY_ALERT_MIGRATABLE_SUBJECT_EXIST_002: MvnCodeFE;
  STUDY_SITE_MODIFY_DESC_CRF_EDIT_DISABLE: MvnCodeFE;
} = {
  SITE_MANAGEMENT_SITE_BREADCRUMB: {
    ko: "임상시험 연구 실시기관을 확인하고 관리할 수 있습니다.",
    en: "Add and manage clinical trial sites.",
  },
  SITE_ADDED: {
    ko: "신규 Site({name})를 등록하였습니다.",
    en: "A new site({name}) has been added.",
  },
  SITE_ADD_FAIL: {
    ko: "신규 Site 등록에 실패하였습니다. ({message})",
    en: "New site registration has been failed. ({message})",
  },
  SITE_EDIT_TITLE: {
    ko: "Site 수정",
    en: "Edit Site",
  },
  SITE_EDIT_SUCC: {
    ko: "기존 Site({site})를 갱신하였습니다.",
    en: "Site({site}) has been updated successfully.",
  },
  SITE_EDIT_FAIL: {
    ko: "기존 Site({site}) 갱신에 실패하였습니다. ({error})",
    en: "Site update has been failed. ({error})",
  },
  IRB_APPROVED_DATE: {
    ko: "IRB 승인 일자",
    en: "IRB Approved Date",
  },
  IRB_APPROVED_DATE_PLACEHOLDER: {
    ko: "IRB 승인 일자를 입력해 주세요.",
    en: "Please enter the IRB approved date.",
  },
  SITE_CONTRACT_DATE_LABEL: {
    ko: "기관 계약 일자",
    en: "Site Contract Date",
  },
  SITE_CONTRACT_DATE_PLACEHOLDER: {
    ko: "기관 계약 일자를 입력해 주세요.",
    en: "Please enter the site contract date.",
  },
  SITE_START_DATE_LABEL: {
    ko: "기관 개시 일자",
    en: "Site Start Date",
  },
  SITE_START_DATE_PLACEHOLDER: {
    ko: "기관 개시 일자를 입력해 주세요.",
    en: "Please enter the site start date.",
  },
  SITE_ADD: {
    ko: "Site 추가",
    en: "Add Site",
  },
  SITE_LABEL: {
    ko: "실시기관",
    en: "Site",
  },
  SITE_NAME: {
    ko: "실시기관 이름",
    en: "Site Name",
  },
  SITE_DROPDOWN_PLACEHOLDER: {
    ko: "실시기관을 선택해 주세요.",
    en: "Please choose your site.",
  },
  SITE_NAME_PLACEHOLDER: {
    ko: "Site name을 입력해 주세요.",
    en: "Please enter the site name.",
  },
  SITE_NUMBER_OF_ENROLLED_SUBJECTS: {
    ko: "등록 대상자 수",
    en: "Number of Enrolled Subjects.",
  },
  SITE_CODE_PLACEHOLDER: {
    ko: "Site Code를 입력해 주세요.",
    en: "Please enter the site code.",
  },
  ALLOCATION: {
    ko: "배정 대상자 수",
    en: "Allocation",
  },
  ALLOCATION_PLACEHOLDER: {
    ko: "배정 대상자 수를 입력해 주세요",
    en: "Please enter the allocation",
  },
  ALLOCATION_DESC: {
    ko: "입력하지 않을 경우 대상자 등록에 제한이 없습니다.",
    en: "If you don't enter the allocation, there is no restriction on subject registration.",
  },
  STUDY_SITE_ADD_SECTION_TITLE_PRINCIPAL_INVESTIGATOR: {
    ko: "연구담당자 정보",
    en: "Principal Investigator Info",
  },
  STUDY_SITE_ADD_LABEL_PRINCIPAL_INVESTIGATOR: {
    ko: "연구담당자",
    en: "Principal Investigator",
  },
  STUDY_SITE_ADD_LABEL_PRINCIPAL_INVESTIGATOR_PHONE_NUMBER: {
    ko: "연구담당자 전화번호",
    en: "Principal Investigator phone number",
  },
  STUDY_SITE_ADD_SECTION_TITLE_CRF: {
    ko: "CRF 정보",
    en: "CRF info",
  },
  STUDY_SITE_ADD_LABEL_EFFECTIVE_DATE: {
    ko: "CRF Version 관리일",
    en: "CRF Version Control Date",
  },
  STUDY_SITE_ADD_LABEL_EFFECTIVE_DATE_TOOLTIP_FOR_ICF_APPROVED_DATE: {
    ko: "* 해당 CRF Version 관리의 기준이 되는 날짜이며, 대상자 서면동의일이 CRF Version 관리일 이후이면 해당 CRF Version이 적용됩니다.",
    en: "* It refers to CRF version control date. If subject consent date is post to the date, this CRF version is applied.",
  },
  STUDY_SITE_ADD_LABEL_EFFECTIVE_DATE_TOOLTIP_FOR_REGISTERED_DATE: {
    ko: "* 해당 CRF Version 관리의 기준이 되는 날짜이며, 대상자 등록일이 CRF Version 관리일 이후이면 해당 CRF Version이 적용됩니다.",
    en: "* It refers to CRF version control date. If subject enrollment date is post to the date, this CRF version is applied.",
  },
  STUDY_SITE_ADD_LABEL_CRF: {
    ko: "CRF",
    en: "CRF",
  },
  STUDY_SITE_MODIFY_SECTION_TITLE_CRF: {
    ko: "CRF 정보",
    en: "CRF Info",
  },
  STUDY_SITE_MODIFY_LABEL_EFFECTIVE_DATE: {
    ko: "Effective Date",
    en: "Effective Date",
  },
  STUDY_SITE_MODIFY_LABEL_CRF: {
    ko: "CRF",
    en: "CRF",
  },
  STUDY_SITE_MODIFY_ALERT_MIGRATABLE_SUBJECT_EXIST_001: {
    ko: "Migrate 할 수 있는 Subject가 {count}건 존재합니다.",
    en: "There are {count} Subjects that can be migrated.",
  },
  STUDY_SITE_MODIFY_ALERT_MIGRATABLE_SUBJECT_EXIST_002: {
    ko: "Migrate를 수행하시겠습니까?",
    en: "Do you want to migrate?",
  },
  STUDY_SITE_MODIFY_DESC_CRF_EDIT_DISABLE: {
    ko: "Subject CRF Migrate가 진행중입니다. Migrate가 완료된 후 Site CRF 수정이 가능합니다.",
    en: "Subject CRF Migrate is in progress. Site CRF can be modified after migration is completed.",
  },
};

MvnCodeInitiate(SITE_LANG);
