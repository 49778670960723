import { useEffect, useState } from "react";
import JSEncrypt from "jsencrypt";
import { useDispatch } from "react-redux";
import { useRoute } from "@maven-surface/hooks";
import { sessionSlice, SessionState } from "@maven-rest/states";
import { v4 as uuidv4 } from "uuid";

const ssogwKey = "ssogw";
const ssogwUuid = "uuid";

interface Props {
  session: SessionState;
}

function generateKeys(async?: boolean): Promise<{ privateKey: string; publicKey: string }> {
  const crypt = new JSEncrypt({ default_key_size: "512" });

  return new Promise((resolve) => {
    if (!async) {
      const keys = crypt.getKey();

      resolve({ privateKey: keys.getPrivateKey(), publicKey: keys.getPublicKey() });

      return;
    }

    crypt.getKey(function () {
      resolve({ privateKey: crypt.getPrivateKey(), publicKey: crypt.getPublicKey() });
    });
  });
}

export function SSO({ session }: Props) {
  const dispatch = useDispatch();
  const { query, state } = useRoute();
  const mode: "SIGN-UP" | "SIGN-IN" = query.mode === "sign-up" ? "SIGN-UP" : "SIGN-IN";
  const from = state.from as unknown as Location;

  const [keys, setKeys] = useState<{ uuid: string; privateKey: string; publicKey: string }>();

  useEffect(() => {
    if (!session?.token && !query[ssogwKey] && !query[ssogwUuid] && !keys) {
      generateKeys()
        .then((res) => {
          const uuid = uuidv4();

          sessionStorage.setItem(uuid, res.privateKey);
          sessionStorage.setItem(
            `${uuid}#rel`,
            (query.redirect as string) ||
              (from
                ? `${from.pathname}${((search) => (search ? `?${search}` : ""))(String(from.search || "").slice(1))}${((hash) =>
                    hash ? `#${hash}` : "")(String(from.hash || "").slice(1))}`
                : "/")
          );
          setKeys({ ...res, uuid });
        })
        .catch(() => {});
    }
  });

  if (session?.token) {
    document.location.href = "/";

    return <></>;
  }

  if (query[ssogwKey] && query[ssogwUuid]) {
    try {
      let encrpyted = "";
      const privKey = sessionStorage.getItem(String(query[ssogwUuid] || "NONE"));
      const crypt = new JSEncrypt();

      crypt.setPrivateKey(privKey || "");

      for (const enc of String(decodeURIComponent(String(query[ssogwKey])) || "").split(",")) {
        encrpyted += crypt.decrypt(enc);
      }

      const payload = JSON.parse(encrpyted);

      if (!payload?.token || !payload?.expiryAt) {
        return (
          <div className="maven-clinical-cloud--sso state--recursive">
            <span className="app-spinner">Invalid SSO Payload</span>
          </div>
        );
      }

      const isValid = ((v) => !v || new Date().getTime() > new Date(Number(v)).getTime() + 10 * 1000)(
        sessionStorage.getItem(payload.token)
      );

      if (isValid) {
        const redirectTo = sessionStorage.getItem(`${query[ssogwUuid]}#rel`);

        sessionStorage.setItem(payload.token, String(new Date().getTime()));
        dispatch(sessionSlice.actions.signIn({ authToken: { ...payload }, redirectTo: redirectTo || "/" }));

        return (
          <div className="maven-clinical-cloud--sso state--redirect">
            <span className="app-spinner" />
          </div>
        );
      } else {
        return (
          <div className="maven-clinical-cloud--sso state--recursive">
            <span className="app-spinner">SSO Token Expired ({sessionStorage.getItem(payload.token) || "NULL"})</span>
          </div>
        );
      }
    } catch (e) {
      document.location.href = "/";

      return (
        <div className="maven-clinical-cloud--sso state--redirect">
          <span className="app-spinner" />
        </div>
      );
    }
  }

  if (keys) {
    document.location.href = `${process.env.REACT_APP_SSO_FE_URL}/auth/${mode.toLowerCase()}/sso?${ssogwKey}=${encodeURIComponent(
      window.btoa(JSON.stringify({ redirect: document.location.href, [ssogwUuid]: keys.uuid, key: keys.publicKey }))
    )}`;
  }

  return (
    <>
      <div className="maven-clinical-cloud--sso state--redirect">
        <span className="app-spinner" />
      </div>
    </>
  );
}
