import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_SUBJECT_LANG: {
  ADD_BTN_SUBJECT_LIST: MvnCodeFE;
  ADD_SITE_PLACEHOLDER_NOT_EXIST: MvnCodeFE;
  ADD_SITE_PLACEHOLDER_AUTHORIZED_NOT_EXIST: MvnCodeFE;
  ADD_SITE_PLACEHOLDER: MvnCodeFE;
  ADD_SITE_LABEL: MvnCodeFE;
  ADD_SUCC: MvnCodeFE;
  ADD_FAIL: MvnCodeFE;
  ADD_BULK_POPUP_HEADER: MvnCodeFE;
  ADD_BULK_POPUP_BTN_REQUEST: MvnCodeFE;
  ADD_BULK_POPUP_LABEL_COUNT: MvnCodeFE;
  ADD_BULK_POPUP_PLACEHOLDER_COUNT: MvnCodeFE;
  ADD_BULK_SUCC: MvnCodeFE;
  ADD_BULK_FAIL: MvnCodeFE;
  ADD_VISIT_SUCC: MvnCodeFE;
  ADD_VISIT_FAIL: MvnCodeFE;
  ADD_CYCLE_SUCC: MvnCodeFE;
  ADD_CYCLE_FAIL: MvnCodeFE;
  CRF_DOWNLOAD_REQUEST: MvnCodeFE;
  CRF_DOWNLOAD_FAIL_BY_PRIV: MvnCodeFE;
  CRF_DOWNLOAD_FAIL_BY_TYPE: MvnCodeFE;
  CRF_DOWNLOAD_FAIL_BY_EXPIRED: MvnCodeFE;
  CRF_DOWNLOAD_FAIL: MvnCodeFE;
  CRF_INITIALIZE_DESCRIPTION: MvnCodeFE;
  CRF_INITIALIZE_SUCC: MvnCodeFE;
  CRF_INITIALIZE_FAIL: MvnCodeFE;
  CRF_SCHEDULE_INITIALIZE_SUCC: MvnCodeFE;
  CRF_SCHEDULE_INITIALIZE_FAIL: MvnCodeFE;
  CRF_STATUS_ESIGN_ON_DIALOG_TITLE: MvnCodeFE;
  CRF_STATUS_ESIGN_OFF_DIALOG_TITLE: MvnCodeFE;
  CRF_STATUS_FORCEDISABLED_ON_SUCC: MvnCodeFE;
  CRF_STATUS_FORCEDISABLED_OFF_SUCC: MvnCodeFE;
  CRF_STATUS_FORCEDISABLED_ON_FAIL: MvnCodeFE;
  CRF_STATUS_FORCEDISABLED_OFF_FAIL: MvnCodeFE;
  CRF_STATUS_SDV_ON: MvnCodeFE;
  CRF_STATUS_SDV_ON_FAIL: MvnCodeFE;
  CRF_STATUS_SDV_OFF: MvnCodeFE;
  CRF_STATUS_SDV_OFF_FAIL: MvnCodeFE;
  CRF_STATUS_FREEZING_ON: MvnCodeFE;
  CRF_STATUS_FREEZING_ON_FAIL: MvnCodeFE;
  CRF_STATUS_FREEZING_OFF: MvnCodeFE;
  CRF_STATUS_FREEZING_OFF_FAIL: MvnCodeFE;
  CRF_STATUS_ESIGN_ON_PASSWORD_DIALOG_TITLE: MvnCodeFE;
  CRF_STATUS_ESIGN_ON: MvnCodeFE;
  CRF_STATUS_ESIGN_ON_FAIL: MvnCodeFE;
  CRF_STATUS_ESIGN_OFF_PASSWORD_DIALOG_TITLE: MvnCodeFE;
  CRF_STATUS_ESIGN_OFF: MvnCodeFE;
  CRF_STATUS_ESIGN_OFF_FAIL: MvnCodeFE;
  CRF_STATUS_ACTION_ON_SUCC: MvnCodeFE;
  CRF_STATUS_ACTION_OFF_SUCC: MvnCodeFE;
  CRF_STATUS_ACTION_ON_FAIL: MvnCodeFE;
  CRF_STATUS_ACTION_OFF_FAIL: MvnCodeFE;
  CRF_STATUS_LOCKING_ON: MvnCodeFE;
  CRF_STATUS_LOCKING_ON_FAIL: MvnCodeFE;
  CRF_STATUS_LOCKING_OFF: MvnCodeFE;
  CRF_STATUS_LOCKING_OFF_FAIL: MvnCodeFE;
  DELETE_VISIT_SUCC: MvnCodeFE;
  DELETE_VISIT_FAIL: MvnCodeFE;
  DELETE_CYCLE_SUCC: MvnCodeFE;
  DELETE_CYCLE_FAIL: MvnCodeFE;
  STUDY_SUBJECT_RERUN_TRIGGER_QUERY_SUCC: MvnCodeFE;
  STUDY_SUBJECT_RERUN_TRIGGER_QUERY_FAIL: MvnCodeFE;
  STUDY_SUBJECT_RERUN_TRIGGER_SIGN: MvnCodeFE;
  VISIT_INITIALIZE_DESCRIPTION: MvnCodeFE;
  VISIT_INITIALIZE_SUCC: MvnCodeFE;
  VISIT_INITIALIZE_FAIL: MvnCodeFE;
  SUBJECT_RANDOM_NO_RETRIEVAL_SUCC: MvnCodeFE;
  SUBJECT_RANDOM_NO_RETRIEVAL_FAIL: MvnCodeFE;
  SUBJECT_RANDOM_NO_RETRIEVAL_DIALOG_TITLE: MvnCodeFE;
  SUBJECT_RANDOM_NO_RETRIEVAL_DIALOG_INPUT_LABEL: MvnCodeFE;
  CRF_STATUS_LOCKING_UNLOCKING_DIALOG_HEADER: MvnCodeFE;
  CRF_STATUS_LOCKING_DIALOG_CONFIRM_BATCH: MvnCodeFE;
  CRF_STATUS_UNLOCKING_DIALOG_CONFIRM_BATCH: MvnCodeFE;
  CRF_STATUS_LOCKING_DIALOG_CONFIRM_CRF: MvnCodeFE;
  CRF_STATUS_UNLOCKING_DIALOG_CONFIRM_CRF: MvnCodeFE;
} = {
  ADD_BTN_SUBJECT_LIST: {
    ko: "Subject List",
    en: "Subject List",
  },
  ADD_SITE_PLACEHOLDER_NOT_EXIST: {
    ko: "등록된 실시기관이 없습니다.",
    en: "There is no registered site.",
  },
  ADD_SITE_PLACEHOLDER_AUTHORIZED_NOT_EXIST: {
    ko: "접근 가능한 실시기관이 없습니다. 스터디 관리자에게 문의해 주세요.",
    en: "There is no accessible site. Please contact the study manager.",
  },
  ADD_SITE_PLACEHOLDER: {
    ko: "실시기관을 선택해 주세요.",
    en: "Please choose Site.",
  },
  ADD_SUCC: {
    ko: "신규 대상자를 등록하였습니다.",
    en: "A new subject has been registered.",
  },
  ADD_FAIL: {
    ko: "신규 대상자 등록에 실패하였습니다. ({message})",
    en: "New subject registration has been failed. ({message})",
  },
  ADD_BULK_POPUP_HEADER: {
    ko: "Add Subject (Bulk)",
    en: "Add Subject (Bulk)",
  },
  ADD_BULK_POPUP_BTN_REQUEST: {
    ko: "Request",
    en: "Request",
  },
  ADD_BULK_POPUP_LABEL_COUNT: {
    ko: "Number of creations",
    en: "Number of creations",
  },
  ADD_BULK_POPUP_PLACEHOLDER_COUNT: {
    ko: "Enter the number of subject to create.",
    en: "Enter the number of subject to create.",
  },
  ADD_BULK_SUCC: {
    ko: "신규 대상자 일괄 등록 요청을 완료했습니다.",
    en: "Completed the request for batch registration of new subjects.",
  },
  ADD_BULK_FAIL: {
    ko: "신규 대상자 일괄 등록 요청에 실패하였습니다. ({message})",
    en: "New subjects batch registration has been failed. ({message})",
  },
  ADD_SITE_LABEL: {
    ko: "실시기관",
    en: "Site",
  },
  ADD_VISIT_SUCC: {
    ko: "성공적으로 Visit을 추가하였습니다.",
    en: "You have added a visit successfully.",
  },
  ADD_VISIT_FAIL: {
    ko: "Visit 추가에 실패하였습니다. ({error})",
    en: "Visit add has been failed. ({error})",
  },
  ADD_CYCLE_SUCC: {
    ko: "Cycle이 추가되었습니다.",
    en: "You have added a cycle successfully.",
  },
  ADD_CYCLE_FAIL: {
    ko: "Cycle 추가에 실패했습니다. ({error})",
    en: "Cycle add has been failed. ({error})",
  },
  CRF_DOWNLOAD_REQUEST: {
    ko: "다운로드가 시작되었습니다.",
    en: "Download has started.",
  },
  CRF_DOWNLOAD_FAIL_BY_PRIV: {
    ko: "다운로드에 실패하였습니다. 권한을 확인해 주세요.",
    en: "Failed to download. Please check your R&P.",
  },
  CRF_DOWNLOAD_FAIL_BY_TYPE: {
    ko: "Study Data 유형이 유효하지 않습니다.",
    en: "Study Data Warehouse Type is invalid.",
  },
  CRF_DOWNLOAD_FAIL_BY_EXPIRED: {
    ko: "다운로드 요청 기한이 만료되었습니다.",
    en: "Download request has expired.",
  },
  CRF_DOWNLOAD_FAIL: {
    ko: "다운로드에 실패하였습니다. 다시 시도해주세요.",
    en: "Failed to download. Please try again.",
  },
  CRF_INITIALIZE_DESCRIPTION: {
    ko: "'CRF({label})' 초기화를 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to initialize the 'CRF({label})' information.",
  },
  CRF_INITIALIZE_SUCC: {
    ko: "'CRF({label})'를 초기화하였습니다.",
    en: "'CRF({label})' has been initialized.",
  },
  CRF_INITIALIZE_FAIL: {
    ko: "'CRF({label})' 초기화에 실패하였습니다. ({error})",
    en: "'CRF({label})' initialization has been failed. ({error})",
  },
  CRF_SCHEDULE_INITIALIZE_SUCC: {
    ko: "현재 CRF를 초기화하였습니다.",
    en: "Initialized the current CRF.",
  },
  CRF_SCHEDULE_INITIALIZE_FAIL: {
    ko: "CRF 초기화에 실패했습니다. ({error})",
    en: "CRF initialize has been failed. ({error})",
  },
  CRF_STATUS_ACTION_ON_SUCC: {
    ko: "선택한 항목에 대해서 {action} 처리 되었습니다.",
    en: "{action} processed successfully.",
  },
  CRF_STATUS_ACTION_OFF_SUCC: {
    ko: "선택한 항목에 대해서 {action} 해제 되었습니다.",
    en: "{action} has inactivated successfully.",
  },
  CRF_STATUS_ACTION_ON_FAIL: {
    ko: "{action} 처리에 실패했습니다. ({error})",
    en: "{action} has been failed. ({error})",
  },
  CRF_STATUS_ACTION_OFF_FAIL: {
    ko: "{action} 해제에 실패했습니다. ({error})",
    en: "{action} inactivate has been failed. ({error})",
  },
  CRF_STATUS_ESIGN_ON_DIALOG_TITLE: {
    ko: "선택된 {text}의 전자서명 처리를 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password for {text} E-Sign.",
  },
  CRF_STATUS_ESIGN_OFF_DIALOG_TITLE: {
    ko: "선택된 {text}의 전자서명 해제를 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to relaese {text} E-Sign.",
  },
  CRF_STATUS_FORCEDISABLED_ON_SUCC: {
    ko: "CRF 페이지가 비활성화 처리되었습니다.",
    en: "You have disabled the CRF Page successfully.",
  },
  CRF_STATUS_FORCEDISABLED_OFF_SUCC: {
    ko: "CRF 페이지가 활성화 처리되었습니다.",
    en: "You have activated the CRF Page successfully.",
  },
  CRF_STATUS_FORCEDISABLED_ON_FAIL: {
    ko: "CRF 페이지 비활성화 처리에 실패하였습니다.",
    en: "Failed to disable CRF Page.",
  },
  CRF_STATUS_FORCEDISABLED_OFF_FAIL: {
    ko: "CRF 페이지 활성화 처리에 실패하였습니다.",
    en: "Failed to activate CRF Page.",
  },
  CRF_STATUS_SDV_ON: {
    ko: "SDV 처리되었습니다.",
    en: "SDV processed successfully.",
  },
  CRF_STATUS_SDV_ON_FAIL: {
    ko: "SDV 처리에 실패하였습니다. ({message})",
    en: "Failed to SDV. ({message})",
  },
  CRF_STATUS_SDV_OFF: {
    ko: "SDV 해제 처리되었습니다.",
    en: "SDV cancelled successfully.",
  },
  CRF_STATUS_SDV_OFF_FAIL: {
    ko: "Freezing 해제 처리에 실패하였습니다. ({message})",
    en: "Failed to cancel freezing. ({message})",
  },
  CRF_STATUS_FREEZING_ON: {
    ko: "Freezing 처리되었습니다.",
    en: "Freezing processed successfully.",
  },
  CRF_STATUS_FREEZING_ON_FAIL: {
    ko: "Freezing 처리에 실패하였습니다. ({message})",
    en: "Failed to freezing. ({message})",
  },
  CRF_STATUS_FREEZING_OFF: {
    ko: "Freezing 해제 처리되었습니다.",
    en: "Unfrozen successfully.",
  },
  CRF_STATUS_FREEZING_OFF_FAIL: {
    ko: "Freezing 해제 처리에 실패하였습니다. ({message})",
    en: "Failed to unfreeze. ({message})",
  },
  CRF_STATUS_ESIGN_ON_PASSWORD_DIALOG_TITLE: {
    ko: "E-Signature 처리를 위해 아이디 및 비밀번호를 입력해 주세요.",
    en: "Please enter your login credential to process E-Signature.",
  },
  CRF_STATUS_ESIGN_ON: {
    ko: "E-Signature 처리되었습니다.",
    en: "E-Signature processed successfully.",
  },
  CRF_STATUS_ESIGN_ON_FAIL: {
    ko: "E-Signature 처리에 실패하였습니다. ({message})",
    en: "Failed to e-signature. ({message})",
  },
  CRF_STATUS_ESIGN_OFF_PASSWORD_DIALOG_TITLE: {
    ko: "E-Signature 해제 처리를 위해 아이디 및 비밀번호를 입력해 주세요.",
    en: "Please enter your login credential to cancel E-Signature.",
  },
  CRF_STATUS_ESIGN_OFF: {
    ko: "E-Signature 해제 처리되었습니다.",
    en: "E-Signature cancelled successfully.",
  },
  CRF_STATUS_ESIGN_OFF_FAIL: {
    ko: "E-Signature 해제 처리에 실패하였습니다. ({message})",
    en: "Failed to e-signature cancel. ({message})",
  },
  CRF_STATUS_LOCKING_ON: {
    ko: "Locking 처리되었습니다.",
    en: "Locking processed successfully.",
  },
  CRF_STATUS_LOCKING_ON_FAIL: {
    ko: "Locking 처리에 실패하였습니다. ({message})",
    en: "Failed to locking. ({message})",
  },
  CRF_STATUS_LOCKING_OFF: {
    ko: "Locking 해제 처리되었습니다.",
    en: "Unlocked successfully.",
  },
  CRF_STATUS_LOCKING_OFF_FAIL: {
    ko: "Locking 해제 처리에 실패하였습니다. ({message})",
    en: "Failed to unlocking. ({message})",
  },
  DELETE_VISIT_SUCC: {
    ko: "Visit을 삭제하였습니다.",
    en: "You have deleted a visit successfully.",
  },
  DELETE_VISIT_FAIL: {
    ko: "Visit 삭제에 실패하였습니다. ({error})",
    en: "Visit deletion has been failed. ({error})",
  },
  DELETE_CYCLE_SUCC: {
    ko: "Cycle이 삭제되었습니다.",
    en: "You have deleted a cycle successfully.",
  },
  DELETE_CYCLE_FAIL: {
    ko: "Cycle 삭제에 실패했습니다. ({error})",
    en: "Cycle deletion has been failed. ({error})",
  },
  STUDY_SUBJECT_RERUN_TRIGGER_QUERY_SUCC: {
    ko: "전체 트리거 재수행에 성공했습니다.",
    en: "Retry of triggers has been successfully.",
  },
  STUDY_SUBJECT_RERUN_TRIGGER_QUERY_FAIL: {
    ko: "전체 트리거 재수행에 실패했습니다.",
    en: "Retry of triggers has been failed.",
  },
  STUDY_SUBJECT_RERUN_TRIGGER_SIGN: {
    ko: "트리거 재수행을 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your login credential to retry triggers.",
  },
  VISIT_INITIALIZE_DESCRIPTION: {
    ko: "{title} 초기화를 위해 비밀번호를 입력해 주세요.",
    en: "Please enter the password to initialize the {title} information.",
  },
  VISIT_INITIALIZE_SUCC: {
    ko: "선택하신 visit을 초기화하였습니다.",
    en: "The visit has been initialized successfully.",
  },
  VISIT_INITIALIZE_FAIL: {
    ko: "Visit 초기화에 실패했습니다. ({error})",
    en: "Visit initialization has been failed. ({error})",
  },
  SUBJECT_RANDOM_NO_RETRIEVAL_SUCC: {
    ko: "대상자 Randomization No 회수에 성공하였습니다.",
    en: "Successfully retrieved the subject Randomization No.",
  },
  SUBJECT_RANDOM_NO_RETRIEVAL_FAIL: {
    ko: "대상자 Randomization No 회수에 실패하였습니다. ({error})",
    en: "Failed to retrieve the subject Randomization No. ({error})",
  },
  SUBJECT_RANDOM_NO_RETRIEVAL_DIALOG_TITLE: {
    ko: "대상자 Randomization No 회수",
    en: "Retrieve the subject Randomization No.",
  },
  SUBJECT_RANDOM_NO_RETRIEVAL_DIALOG_INPUT_LABEL: {
    ko: "회수 사유",
    en: "Reason for Retrieval.",
  },
  CRF_STATUS_LOCKING_UNLOCKING_DIALOG_HEADER: {
    ko: "안내",
    en: "Confirm",
  },
  CRF_STATUS_LOCKING_DIALOG_CONFIRM_BATCH: {
    ko: "{count}개의 {unit}에 대해서 Lock 처리됩니다. 진행하시겠습니까?",
    en: "{count} of {unit} will be locked. Are you sure you want to proceed?",
  },
  CRF_STATUS_UNLOCKING_DIALOG_CONFIRM_BATCH: {
    ko: "{count}개의 {unit}에 대해서 Unlock 처리됩니다. 진행하시겠습니까?",
    en: "{count} of {unit} will be unlocked. Are you sure you want to proceed?",
  },
  CRF_STATUS_LOCKING_DIALOG_CONFIRM_CRF: {
    ko: "해당 CRF에 대해서 Lock 처리됩니다. 진행하시겠습니까?",
    en: "The CRF page will be locked. Are you sure you want to proceed?",
  },
  CRF_STATUS_UNLOCKING_DIALOG_CONFIRM_CRF: {
    ko: "해당 CRF에 대해서 Unlock 처리됩니다. 진행하시겠습니까?",
    en: "The CRF page will be unlocked. Are you sure you want to proceed?",
  },
};

MvnCodeInitiate(STUDY_SUBJECT_LANG);
