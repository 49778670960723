/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_SUBJECT_BLIND_ACTION: {
  BLIND_BREADCRUMB: MvnCodeFE;
  UNBLIND_DIALOG_HEADER: MvnCodeFE;
  UNBLIND_DIALOG_INPUT_PLACEHOLDER_REASON: MvnCodeFE;
  UNBLIND_REQUEST_POPUP_MESSAGE: MvnCodeFE;
  UNBLIND_REQUEST_TOOLTIP: MvnCodeFE;
  UNBLIND_REQUEST_SUCC: MvnCodeFE;
  UNBLIND_REQUEST_FAIL: MvnCodeFE;
  UNBLIND_REQUEST_APPROVE_SUCC: MvnCodeFE;
  UNBLIND_REQUEST_APPROVE_FAIL: MvnCodeFE;
} = {
  BLIND_BREADCRUMB: {
    ko: "눈가림과 관련된 요청을 조회하거나 눈가림 해제 요청을 승인할 수 있습니다.",
    en: "Inquire blind requests and approve unblind requests.",
  },
  UNBLIND_DIALOG_HEADER: {
    ko: "눈가림 해제",
    en: "Unblind",
  },
  UNBLIND_DIALOG_INPUT_PLACEHOLDER_REASON: {
    ko: "상세 사유를 입력해 주세요,",
    en: "Please enter a reason",
  },
  UNBLIND_REQUEST_POPUP_MESSAGE: {
    ko: "[{subject}] 대상자의 눈가림 해제를 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to unblind [{subject}] subject.",
  },
  UNBLIND_REQUEST_TOOLTIP: {
    ko: "눈가림 해제 요청",
    en: "Request Unblind",
  },
  UNBLIND_REQUEST_SUCC: {
    ko: "눈가림 해제 요청이 등록되었습니다.",
    en: "The unblind request has been registered successfully.",
  },
  UNBLIND_REQUEST_FAIL: {
    ko: "눈가림 해제 요청에 실패했습니다. ({message})",
    en: "Failed to register the unblind request. ({message})",
  },
  UNBLIND_REQUEST_APPROVE_SUCC: {
    ko: "눈가림 해제 승인이 완료되었습니다.",
    en: "The unblind request is approved successfully.",
  },
  UNBLIND_REQUEST_APPROVE_FAIL: {
    ko: "눈가림 해제 승인에 실패했습니다. ({message})",
    en: "Failed to approve for unblind request. ({message})",
  },
};

MvnCodeInitiate(STUDY_SUBJECT_BLIND_ACTION);
