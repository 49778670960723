import styled from "@emotion/styled";
import { GrButton, GrCheckbox, palette } from "@jnpmedi/grebob";
import { GrChip } from "@jnpmedi/grebob/dist/components/data/data-display/GrChip";
import { GrStack } from "@jnpmedi/grebob/dist/components/layout/GrStack";
import { generalDateFormat } from "@safety-lib/const";
import { SAFETY_NOTICE_POPUP_KEY } from "./Popup";
import { StyledPopupInfoWrapper, StyledPopupWrapper, StyledTextWrapper } from "./Popup.style";
import { Markdown } from "../Markdown";
import { COMMON_LANG } from "@maven-msg/lang";
import { MvnCodeFE } from "@maven-msg/lang/const";
import { MvnLanguage, MvnMessage } from "@maven-msg/lib/Message";
import { isAdminNoticeLang, readableMavenProductServiceWithClinicalCloud, readableNoticeType } from "@maven-rest/admin/Notice.conv";
import { AdminNoticeType, AdminNoticeWithContent } from "@maven-rest/admin/Notice.dto";
import { LoLang } from "@maven-rest/common/common.types";
import { LOCALE_LANG_KEY, setCookie } from "@maven-rest/states";
import { getCurrentDate } from "@maven-helper/functions/Misc.function";
import dayjs from "dayjs";

const GrChipWrapper = styled("span")(
  ({ color }: { color: "red" | "green" }) =>
    color === "red" && {
      ".GrChip": {
        backgroundColor: palette.orange010,
        borderColor: palette.orange020,
      },
    }
);

function NoticeBadge(req: { type: AdminNoticeType }) {
  const badgeColor = (() => {
    switch (req.type) {
      case AdminNoticeType.ResolvedIncident:
        return "green";
      case AdminNoticeType.SystemOperation:
        return "red";
      default:
        throw new Error(`Unexpected flow, not supported notice type. (type: ${req.type})`);
    }
  })();

  return (
    <GrChipWrapper color={badgeColor}>
      <GrChip color={badgeColor} size="medium" variant="outlined-soft" shape="circle" label={readableNoticeType(req.type)} />
    </GrChipWrapper>
  );
}

const StyledContentWrapper = styled("div")({
  display: "flex",
  flexBasis: "calc(100% - 2.75rem)",
  flexDirection: "column",
  overflow: "auto",
});

interface NoticePopupProps {
  notice: AdminNoticeWithContent;
  onClose: (id: bigint) => void;
}

export function NoticePopup(props: NoticePopupProps) {
  const { notice, onClose } = props;
  const { type, service, dueAt, title, content } = notice;

  const localStorageLanguage = localStorage.getItem(LOCALE_LANG_KEY) ?? "";
  const lang = isAdminNoticeLang(localStorageLanguage) ? localStorageLanguage : LoLang.KO;

  const handleNoticePopupDoNotShowToday = () => {
    const currentDate = getCurrentDate();

    setCookie(SAFETY_NOTICE_POPUP_KEY(notice.id), dayjs(currentDate).format(generalDateFormat), dayjs(dueAt).add(1, "day").toDate());

    onClose(notice.id);
  };

  return (
    <StyledPopupWrapper>
      <GrStack height="calc(100% - 40px)" flexDirection="column">
        <StyledTextWrapper
          fontSize="1.25rem"
          fontWeight={700}
          style={{ whiteSpace: "wrap", wordBreak: "break-all", marginBottom: "1.5rem" }}
        >
          {MvnMessage(title as MvnCodeFE, undefined, undefined, MvnLanguage[lang])}
        </StyledTextWrapper>
        <StyledContentWrapper>
          <StyledPopupInfoWrapper style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <NoticeBadge type={type} />
            <StyledTextWrapper fontSize="1rem" fontWeight={700}>
              {readableMavenProductServiceWithClinicalCloud(service)}
            </StyledTextWrapper>
          </StyledPopupInfoWrapper>
          <Markdown text={MvnMessage(content as MvnCodeFE, undefined, undefined, MvnLanguage[lang])} />
        </StyledContentWrapper>
      </GrStack>
      <GrStack height="32px" margin="8px 0 0 0" justifyContent="space-between" alignItems="center">
        <GrCheckbox
          id="check"
          size="medium"
          type="rectangle"
          onChange={handleNoticePopupDoNotShowToday}
          style={{ padding: "0px", marginRight: "6px" }}
          label={{ text: MvnMessage(COMMON_LANG.DO_NOT_SHOW_TODAY), style: { fontSize: "13px", fontWeight: 400 } }}
        />
        <GrButton color="grey" style={{ width: "80px", height: "32px" }} onClick={() => onClose(notice.id)}>
          {MvnMessage({ en: "Close", ko: "닫기" })}
        </GrButton>
      </GrStack>
    </StyledPopupWrapper>
  );
}
