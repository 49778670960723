import { LoLang, LoString, MavenProductService, MavenService, PickEnum, UnionRight } from "../common/common.types";
import { Paginatable } from "../common/Paginatable.dto";

export class AdminNoticeCriteria {
  static MAX_TITLE_LENGTH = 200;
}

export class AdminNotice {
  id: bigint;
  type: AdminNoticeType;
  service: MavenProductServiceWithClinicalCloud;
  startAt: Date;
  dueAt: Date;
  createdAt: Date;
}

export enum AdminNoticeType {
  SystemOperation = "SYSTEM_OPERATION",
  ResolvedIncident = "RESOLVED_INCIDENT",
}

export type MavenProductServiceWithClinicalCloud = MavenProductService | PickEnum<MavenService, MavenService.MavenClinicalCloud>;

export type AdminNoticeContent = {
  lang: AdminNoticeLang;
  title: string;
  content: string;
};

export type AdminNoticeTitleContent = {
  title: Partial<LoString>;
  content: Partial<LoString>;
};

export type AdminNoticeWithContent = AdminNotice & AdminNoticeTitleContent;

export type AdminNoticeLang = PickEnum<LoLang, LoLang.EN | LoLang.KO>;

export class CreateNoticeRequest {
  type: AdminNoticeType;
  service: MavenProductServiceWithClinicalCloud;
  contents: Array<AdminNoticeContent>;
  startAt: Date;
  dueAt: Date;
}

export class CreateNoticeResponse {
  notice: AdminNotice;
}

export class PaginateNoticesRequest {}

export type PaginateNoticesResponse = Paginatable<AdminNoticeWithContent>;

export class GetNoticeByIdRequest {
  noticeId: bigint;
}

export class GetNoticeByIdResponse {
  notice: AdminNoticeWithContent;
}

export type ModifyNoticeByIdRequest = Partial<Pick<AdminNotice, "type" | "service" | "startAt" | "dueAt">> & {
  noticeId: bigint;
  contents?: Array<UnionRight<AdminNoticeContent, Partial<Pick<AdminNoticeContent, "title" | "content">>>>;
};

export class ModifyNoticeByIdResponse {
  notice: AdminNoticeWithContent;
}
