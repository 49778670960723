import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_DW_LANG: {
  STUDY_DW_DB_SPECIFICATIONS_BREADCRUMB: MvnCodeFE;
  STUDY_DW_BLANK_CRFS_BREADCRUMB: MvnCodeFE;
  STUDY_DW_DATA_CRFS_BREADCRUMB: MvnCodeFE;
  STUDY_DW_DATASET_BREADCRUMB: MvnCodeFE;
  STUDY_DW_AUDIT_TRAIL_BREADCRUMB: MvnCodeFE;
  STUDY_DW_DATA_REPORTS_BREADCRUMB: MvnCodeFE;
  STUDY_DW_COMMENTS_BREADCRUMB: MvnCodeFE;
  STUDY_DW_OPTIONS_TITLE: MvnCodeFE;
  STUDY_DW_CRF_TITLE: MvnCodeFE;
  STUDY_DW_DOMAIN_TITLE: MvnCodeFE;
  STUDY_DW_SITE_TITLE: MvnCodeFE;
  STUDY_DW_SUBJECT_TITLE: MvnCodeFE;
  STUDY_DW_VISIT_TITLE: MvnCodeFE;
  DB_SPEC_OPTION_SEPARATED_LABEL: MvnCodeFE;
  DB_SPEC_OPTION_SEPARATED_DESC: MvnCodeFE;
  DB_SPEC_REQUEST_SUCC: MvnCodeFE;
  DB_SPEC_REQUEST_FAIL: MvnCodeFE;
  BLANK_CRF_CRF_VERSION_LABEL: MvnCodeFE;
  BLANK_CRF_CRF_VERSION_PLACEHOLDER: MvnCodeFE;
  BLANK_CRF_OPTION_SCHEDULE_LABEL: MvnCodeFE;
  BLANK_CRF_OPTION_SCHEDULE_DESC: MvnCodeFE;
  BLANK_CRF_OPTION_HORIZONTAL_SCHEDULE_LABEL: MvnCodeFE;
  BLANK_CRF_OPTION_HORIZONTAL_SCHEDULE_DESC: MvnCodeFE;
  BLANK_CRF_OPTION_ANNOTATION_LABEL: MvnCodeFE;
  BLANK_CRF_OPTION_ANNOTATION_DESC: MvnCodeFE;
  BLANK_CRF_REQUEST_SUCC: MvnCodeFE;
  BLANK_CRF_REQUEST_FAIL: MvnCodeFE;
  DATASET_OPTIONS_INCL_SAVING_DATE_LABEL: MvnCodeFE;
  DATASET_OPTIONS_INCL_ITEM_CODE_TEXT_LABEL: MvnCodeFE;
  DATASET_OPTIONS_ONLY_ITEM_LABEL: MvnCodeFE;
  DATASET_OPTIONS_MERGE_SHEET: MvnCodeFE;
  DATASET_REQUEST_SUCC: MvnCodeFE;
  DATASET_REQUEST_FAIL: MvnCodeFE;
  AUDIT_TRAIL_ALL_DATE_LABEL: MvnCodeFE;
  AUDIT_TRAIL_CATEGORY_LABEL: MvnCodeFE;
  AUDIT_TRAIL_CATEGORY_PLACEHOLDER: MvnCodeFE;
  AUDIT_TRAIL_REQUEST_SUCC: MvnCodeFE;
  AUDIT_TRAIL_REQUEST_FAIL: MvnCodeFE;
  SUBJECT_CRF_OPTIONS_INCL_AUDIT_TRAIL_LABEL: MvnCodeFE;
  SUBJECT_CRF_OPTIONS_INCL_AUDIT_TRAIL_DESC: MvnCodeFE;
  SUBJECT_CRF_REQUEST_SUCC: MvnCodeFE;
  SUBJECT_CRF_REQUEST_FAIL: MvnCodeFE;
  HISTORY_DOWNLOAD_REQUEST: MvnCodeFE;
  HISTORY_DOWNLOAD_FAIL_BY_PRIV: MvnCodeFE;
  HISTORY_DOWNLOAD_FAIL_BY_TYPE: MvnCodeFE;
  HISTORY_DOWNLOAD_FAIL_BY_EXPIRED: MvnCodeFE;
  HISTORY_DOWNLOAD_FAIL: MvnCodeFE;
  STUDY_DW_COMMENTS_ALL_DATE_LABEL: MvnCodeFE;
  STUDY_DW_COMMENTS_REQUEST_SUCC: MvnCodeFE;
  STUDY_DW_COMMENTS_REQUEST_FAIL: MvnCodeFE;
} = {
  STUDY_DW_DB_SPECIFICATIONS_BREADCRUMB: {
    ko: "DB Specifications를 내려받을 수 있습니다.",
    en: "Download DB specifications.",
  },
  STUDY_DW_BLANK_CRFS_BREADCRUMB: {
    ko: "Blank CRFs를 내려받을 수 있습니다.",
    en: "Download blank CRFs.",
  },
  STUDY_DW_DATA_CRFS_BREADCRUMB: {
    ko: "Data CRFs를 내려받을 수 있습니다.",
    en: "Download data CRFs.",
  },
  STUDY_DW_DATASET_BREADCRUMB: {
    ko: "입력된 대상자의 데이터를 내려받을 수 있습니다.",
    en: "Download subject data.",
  },
  STUDY_DW_AUDIT_TRAIL_BREADCRUMB: {
    ko: "Audit Trail을 내려받을 수 있습니다.",
    en: "Download audit trail.",
  },
  STUDY_DW_DATA_REPORTS_BREADCRUMB: {
    ko: "스터디 운영에 필요한 Report를 내려받을 수 있습니다.",
    en: "Download reports for study operation.",
  },
  STUDY_DW_COMMENTS_BREADCRUMB: {
    ko: "Comments를 내려받을 수 있습니다.",
    en: "Download comments.",
  },
  STUDY_DW_OPTIONS_TITLE: {
    ko: "Options",
    en: "Options",
  },
  STUDY_DW_CRF_TITLE: {
    ko: "CRF",
    en: "CRF",
  },
  STUDY_DW_DOMAIN_TITLE: {
    ko: "Domain",
    en: "Domain",
  },
  STUDY_DW_SITE_TITLE: {
    ko: "Site",
    en: "Site",
  },
  STUDY_DW_SUBJECT_TITLE: {
    ko: "Subject",
    en: "Subject",
  },
  STUDY_DW_VISIT_TITLE: {
    ko: "Visit",
    en: "Visit",
  },
  DB_SPEC_OPTION_SEPARATED_LABEL: {
    ko: "Module별 Sheet 분리",
    en: "Sheet Separation per Module",
  },
  DB_SPEC_OPTION_SEPARATED_DESC: {
    ko: "EDC, eCOA, AE/SAE Report, IPM 기능별로 Sheet를 구분하여 다운로드 합니다.",
    en: "Seperate sheets to be created by module (EDC, eCOA, AE/SAE Report, IPM)",
  },
  DB_SPEC_REQUEST_SUCC: {
    ko: "DB Specifications 생성이 요청되었습니다.",
    en: "Creation of DB Specifications was requested.",
  },
  DB_SPEC_REQUEST_FAIL: {
    ko: "DB Specifications 생성 요청에 실패하였습니다. ({message})",
    en: "Failed to create DB Specifications.",
  },
  BLANK_CRF_CRF_VERSION_LABEL: {
    ko: "CRF Version",
    en: "CRF Version",
  },
  BLANK_CRF_CRF_VERSION_PLACEHOLDER: {
    ko: "CRF Version을 선택해 주세요.",
    en: "Select CRF Version...",
  },
  BLANK_CRF_OPTION_SCHEDULE_LABEL: {
    ko: "Schedule Table 포함",
    en: "Include Schedule Table",
  },
  BLANK_CRF_OPTION_SCHEDULE_DESC: {
    ko: "대상자 Visit Schedule Table을 포함합니다.",
    en: "Include subject's visit schedule table.",
  },
  BLANK_CRF_OPTION_HORIZONTAL_SCHEDULE_LABEL: {
    ko: "Schedule Table 가로 방향 출력",
    en: "Print Schedule Table as Landscape",
  },
  BLANK_CRF_OPTION_HORIZONTAL_SCHEDULE_DESC: {
    ko: "대상자 Visit Schedule Table을 가로 방향으로 출력합니다.",
    en: "Print subject's visit schedule table as landscape orientation.",
  },
  BLANK_CRF_OPTION_ANNOTATION_LABEL: {
    ko: "Annotation 포함",
    en: "Include Annotation",
  },
  BLANK_CRF_OPTION_ANNOTATION_DESC: {
    ko: "Domain과 Item Variable을 포함합니다.",
    en: "Include domain and item variables.",
  },
  BLANK_CRF_REQUEST_SUCC: {
    ko: "Blank CRFs 생성이 요청되었습니다.",
    en: "Creation of Blank CRFs was requested.",
  },
  BLANK_CRF_REQUEST_FAIL: {
    ko: "Blank CRFs 생성 요청에 실패하였습니다. ({message})",
    en: "Failed to create Blank CRFs. ({message})",
  },
  DATASET_OPTIONS_INCL_SAVING_DATE_LABEL: {
    ko: "최종 저장시각 정보 포함",
    en: "Include last saved time data",
  },
  DATASET_OPTIONS_INCL_ITEM_CODE_TEXT_LABEL: {
    ko: "아이템 Label 병행 표기 (e.g. 1:YES)",
    en: "Include item label (e.g. 1:YES)",
  },
  DATASET_OPTIONS_ONLY_ITEM_LABEL: {
    ko: "아이템 Label 단독 표기 (e.g. YES)",
    en: "Only item label (e.g. YES)",
  },
  DATASET_OPTIONS_MERGE_SHEET: {
    ko: "단일 Sheet 사용(Table 포함 Domain 제외)",
    en: "Use single sheet(Excluding Domain that contain Table)",
  },
  DATASET_REQUEST_SUCC: {
    ko: "Dataset 생성이 요청되었습니다.",
    en: "Creation of Dataset was requested.",
  },
  DATASET_REQUEST_FAIL: {
    ko: "Dataset 생성 요청에 실패하였습니다. ({message})",
    en: "Failed to create Dataset.",
  },
  AUDIT_TRAIL_ALL_DATE_LABEL: {
    ko: "All Date",
    en: "All Date",
  },
  AUDIT_TRAIL_CATEGORY_LABEL: {
    ko: "Category",
    en: "Category",
  },
  AUDIT_TRAIL_CATEGORY_PLACEHOLDER: {
    ko: "Category를 선택해 주세요.",
    en: "Select Category...",
  },
  AUDIT_TRAIL_REQUEST_SUCC: {
    ko: "Audit Trail 생성이 요청되었습니다.",
    en: "An Audit Trail has been requested.",
  },
  AUDIT_TRAIL_REQUEST_FAIL: {
    ko: "Audit Trail 생성 요청에 실패하였습니다. ({message})",
    en: "Failed to create an Audit Trail. ({message})",
  },
  SUBJECT_CRF_OPTIONS_INCL_AUDIT_TRAIL_LABEL: {
    ko: "Audit Trail 정보 포함",
    en: "Include Audit Trail Info",
  },
  SUBJECT_CRF_OPTIONS_INCL_AUDIT_TRAIL_DESC: {
    ko: "Data CRFs 하단에 Audit Trail 정보를 포함합니다.",
    en: "Include Audit Trail information at the bottom of Data CRFs.",
  },
  SUBJECT_CRF_REQUEST_SUCC: {
    ko: "Data CRFs 생성이 요청되었습니다.",
    en: "Data CRFs has been requested.",
  },
  SUBJECT_CRF_REQUEST_FAIL: {
    ko: "Data CRFs 생성 요청에 실패하였습니다. ({message})",
    en: "Failed to create Data CRFs. ({message})",
  },
  HISTORY_DOWNLOAD_REQUEST: {
    ko: "다운로드가 시작되었습니다.",
    en: "Download has started.",
  },
  HISTORY_DOWNLOAD_FAIL_BY_PRIV: {
    ko: "다운로드에 실패하였습니다. 권한을 확인해 주세요.",
    en: "Failed to download. Please check your R&P.",
  },
  HISTORY_DOWNLOAD_FAIL_BY_TYPE: {
    ko: "Study Data Warehouse 유형이 유효하지 않습니다.",
    en: "Study Data Warehouse Type is invalid.",
  },
  HISTORY_DOWNLOAD_FAIL_BY_EXPIRED: {
    ko: "다운로드 요청 기한이 만료되었습니다.",
    en: "Download request has expired.",
  },
  HISTORY_DOWNLOAD_FAIL: {
    ko: "다운로드에 실패하였습니다. 다시 시도해주세요.",
    en: "Failed to download. Please try again.",
  },
  STUDY_DW_COMMENTS_ALL_DATE_LABEL: {
    ko: "All Date",
    en: "All Date",
  },
  STUDY_DW_COMMENTS_REQUEST_SUCC: {
    ko: "Comments 생성이 요청되었습니다.",
    en: "Creation of Comments was requested.",
  },
  STUDY_DW_COMMENTS_REQUEST_FAIL: {
    ko: "Comments 생성 요청에 실패하였습니다. ({message})",
    en: "Failed to create Comments. ({message})",
  },
};

MvnCodeInitiate(STUDY_DW_LANG);
