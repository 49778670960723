import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_WORKSPACE_ROLE_CODE: {
  SAFETY_WORKSPACE_ROLE_INVALID_NAME_MISSING: MvnCodeBE;
  SAFETY_WORKSPACE_ROLE_INVALID_NAME_DUPLICATE: MvnCodeBE;
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_ROLE_DUPLICATE: MvnCodeBE;
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_NAME_LENGTH_EXCEED: MvnCodeBE;
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_ROLE_NOT_EXIST: MvnCodeBE;
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_PRIVILEGE_NOT_FOUND: MvnCodeBE;
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_OWNER_ROLE_REMOVE_DENIED: MvnCodeBE;
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_OWNER_ROLE_DEACTIVATE_DENIED: MvnCodeBE;
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_OWNER_ROLE_NAME_MODIFY_DENIED: MvnCodeBE;
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_ROLE_IN_USE: MvnCodeBE;
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_DELETED_ROLE: MvnCodeBE;
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_ONGOING_WORKFLOW: MvnCodeBE;
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_EXTERNAL_PV_USER: MvnCodeBE;
} = {
  SAFETY_WORKSPACE_ROLE_INVALID_NAME_MISSING: {
    status: 400,
    ko: "Role의 이름은 필수 값입니다.",
    en: "Role name required.",
  },
  SAFETY_WORKSPACE_ROLE_INVALID_NAME_DUPLICATE: {
    status: 400,
    ko: "Role의 이름이 중복되었습니다. (name: {name})",
    en: "Role name is duplicated. (name: {name})",
  },
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_ROLE_DUPLICATE: {
    status: 400,
    ko: "요청 본문에 중복된 Role이 존재합니다.",
    en: "Duplicate Role exist in request.",
  },
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_NAME_LENGTH_EXCEED: {
    status: 400,
    ko: "Role의 이름은 최대 {length}자 입니다.",
    en: "Role name can be up to {length} characters long.",
  },
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_ROLE_NOT_EXIST: {
    status: 400,
    ko: "존재하지 않는 role은 수정할 수 없습니다.",
    en: "Not existed role can not be modified.",
  },
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_PRIVILEGE_NOT_FOUND: {
    status: 400,
    ko: "존재하지 않는 권한입니다. ({privilege})",
    en: "Not found privilege. ({privilege})",
  },
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_OWNER_ROLE_REMOVE_DENIED: {
    status: 400,
    ko: "Owner role은 제거할 수 없습니다.",
    en: "Owner role can not be removed.",
  },
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_OWNER_ROLE_DEACTIVATE_DENIED: {
    status: 400,
    ko: "Owner role은 비활성화 할 수 없습니다.",
    en: "Owner role can not be deactivate.",
  },
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_OWNER_ROLE_NAME_MODIFY_DENIED: {
    status: 400,
    ko: "Owner role의 이름은 수정할 수 없습니다.",
    en: "Owner role name can not be modified.",
  },
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_ROLE_IN_USE: {
    status: 400,
    ko: "사용 중인 Role입니다. (role: {role})",
    en: "Role in use. (role: {role})",
  },
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_DELETED_ROLE: {
    status: 400,
    ko: "삭제된 Role은 수정이 불가능합니다.",
    en: "Unable to edit deleted role.",
  },
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_ONGOING_WORKFLOW: {
    status: 400,
    ko: "진행중인 workflow가 있는 member의 role 및 권한은 수정할 수 없습니다.",
    en: "Role and privilege of member with ongoing workflow can not be modified.",
  },
  SAFETY_WORKSPACE_ROLE_MODIFY_INVALID_EXTERNAL_PV_USER: {
    status: 400,
    ko: "External Member의 Role에 PV User 권한을 부여할 수 없습니다.",
    en: "Cannot grant PV User privilege to a role of external member.",
  },
};

MvnCodeInitiate(SAFETY_WORKSPACE_ROLE_CODE);
