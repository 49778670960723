import { MvnCodeBE } from "../const";

export const SAFETY_CASE_CAPTURE_CODE: {
  CASE_CAPTURE_FILE_EXTENSTION_INVALID: MvnCodeBE;
  CASE_CAPTURE_FILE_SIZE_EXCEED: MvnCodeBE;
  CASE_CAPTURE_REPORT_VALIDATE_ERROR_NOT_AVAILABLE_WORKFLOW_STATUS: MvnCodeBE;
  CASE_CAPTURE_REPORT_NO_CHANGES: MvnCodeBE;
  CASE_CAPTURE_CANNOT_MODIFY_CASE_LOCKED: MvnCodeBE;
  CASE_CAPTURE_UNAUTHORIZED_WORKFLOW_STEP: MvnCodeBE;
} = {
  CASE_CAPTURE_FILE_EXTENSTION_INVALID: {
    status: 400,
    ko: "확장자가 {type}인 파일은 업로드할 수 없습니다.",
    en: "File with extension {type} cannot be uploaded.",
  },
  CASE_CAPTURE_FILE_SIZE_EXCEED: {
    status: 400,
    ko: "파일은 최대 {mb}MB까지 업로드할 수 있습니다.",
    en: "You can upload a file up to {mb}MB.",
  },
  CASE_CAPTURE_REPORT_VALIDATE_ERROR_NOT_AVAILABLE_WORKFLOW_STATUS: {
    status: 400,
    ko: "Workflow가 완료된 리포트는 변경할 수 없습니다.",
    en: "Reports that have completed the workflow cannot be changed.",
  },
  CASE_CAPTURE_REPORT_NO_CHANGES: {
    status: 400,
    ko: "변경사항이 없습니다.",
    en: "There are no changes.",
  },
  CASE_CAPTURE_CANNOT_MODIFY_CASE_LOCKED: {
    status: 400,
    ko: "잠금 상태의 케이스는 변경할 수 없습니다.",
    en: "Cases that are locked cannot be changed.",
  },
  CASE_CAPTURE_UNAUTHORIZED_WORKFLOW_STEP: {
    status: 403,
    ko: "현재 Workflow 상태에서 저장 권한이 없습니다.",
    en: "You do not have permission to save at the current workflow status.",
  },
};
