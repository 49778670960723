import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const INQUIRY_LANG: {
  INQUIRY_ADD_BTN_LIST: MvnCodeFE;
  INQUIRY_ADD_TITLE_LABEL: MvnCodeFE;
  INQUIRY_ADD_TITLE_PLACEHOLDER: MvnCodeFE;
  INQUIRY_ADD_CONTENT_LABEL: MvnCodeFE;
  INQUIRY_ADD_CONTENT_PLACEHOLDER: MvnCodeFE;
  INQUIRY_ADD_COMPLETE_SUCCESS: MvnCodeFE;
  INQUIRY_ADD_COMPLETE_FAIL: MvnCodeFE;
  INQUIRY_ADD_CATEGORY_PLACEHOLDER: MvnCodeFE;
  INQUIRY_ADD_STUDY_PLACEHOLDER: MvnCodeFE;
  INQUIRY_ADD_TYPE_PLACEHOLDER: MvnCodeFE;
  INQUIRY_ANSWER_PLACEHOLDER: MvnCodeFE;
  INQUIRY_ANSWER_SUCCESS: MvnCodeFE;
  INQUIRY_ANSWER_FAIL: MvnCodeFE;
  INQUIRY_EMAIL_TEMPLATE_TITLE: MvnCodeFE;
  INQUIRY_EMAIL_TEMPLATE_CONTENT_LABEL: MvnCodeFE;
  INQUIRY_EMAIL_TEMPLATE_CONTENT_DESCRIPTION: MvnCodeFE;
} = {
  INQUIRY_ADD_BTN_LIST: {
    ko: "Inquiry List",
    en: "Inquiry List",
  },
  INQUIRY_ADD_TITLE_LABEL: {
    ko: "제목",
    en: "Title",
  },
  INQUIRY_ADD_TITLE_PLACEHOLDER: {
    ko: "질문 제목을 입력해 주세요.",
    en: "Type in the inquiry title.",
  },
  INQUIRY_ADD_CONTENT_LABEL: {
    ko: "내용",
    en: "Content",
  },
  INQUIRY_ADD_CONTENT_PLACEHOLDER: {
    ko: "질문 내용을 입력해 주세요.",
    en: "Type in the inquiry content",
  },
  INQUIRY_ADD_COMPLETE_SUCCESS: {
    ko: "질문을 완료하였습니다. 빠른시간내에 답변드리겠습니다.",
    en: "Inquiry has been submitted successfully. We will get back as soon as possible.",
  },
  INQUIRY_ADD_COMPLETE_FAIL: {
    ko: "질문에 실패하였습니다. ({error})",
    en: "Inquiry submission has failed. ({error})",
  },
  INQUIRY_ADD_CATEGORY_PLACEHOLDER: {
    ko: "Category를 선택해 주세요.",
    en: "Please select a category.",
  },
  INQUIRY_ADD_STUDY_PLACEHOLDER: {
    ko: "Study를 선택해 주세요.",
    en: "Please select a study.",
  },
  INQUIRY_ADD_TYPE_PLACEHOLDER: {
    ko: "Type을 선택해 주세요.",
    en: "Please select a type.",
  },
  INQUIRY_ANSWER_PLACEHOLDER: {
    ko: "답변을 입력해 주세요.",
    en: "Please enter your answer.",
  },
  INQUIRY_ANSWER_SUCCESS: {
    ko: "답변이 완료되었습니다.",
    en: "Answer is completed.",
  },
  INQUIRY_ANSWER_FAIL: {
    ko: "답변에 실패했습니다. ({error})",
    en: "Answer has been failed. ({error})",
  },
  INQUIRY_EMAIL_TEMPLATE_TITLE: {
    ko: "Maven CDMS System User({{USER_EMAIL}})의 1:1 문의사항이 등록되었습니다.",
    en: "Maven CDMS System User's ({{USER_EMAIL}}) question has been submitted.",
  },
  INQUIRY_EMAIL_TEMPLATE_CONTENT_LABEL: {
    ko: "{{USER_EMAIL}} 사용자의 1:1 문의 사항",
    en: "{{USER_EMAIL}} User's inquiries",
  },
  INQUIRY_EMAIL_TEMPLATE_CONTENT_DESCRIPTION: {
    ko: "<strong>{{TYPE}}</strong>의 <strong>{{CATEGORY}}</strong> <strong>{{STUDY_NAME}}</strong>에 관련된 문의사항입니다.",
    en: "Question regarding <strong>{{TYPE}}'s</strong> <strong>{{CATEGORY}}</strong> <strong>{{STUDY_NAME}}</strong>.",
  },
};

MvnCodeInitiate(INQUIRY_LANG);
