import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_IP_AUTO_CONDITION_LANG: {
  CDMS_STUDY_IP_AUTO_CONDITION_REQUEST_LABEL_IP_CATEGORY: MvnCodeFE;
  CDMS_STUDY_IP_AUTO_CONDITION_REQUEST_PLACEHOLDER_IP_CATEGORY: MvnCodeFE;
  CDMS_STUDY_IP_AUTO_CONDITION_REQUEST_LABEL_MINIMUM_QUANTITY_BY_PRODUCT: MvnCodeFE;
} = {
  CDMS_STUDY_IP_AUTO_CONDITION_REQUEST_LABEL_IP_CATEGORY: {
    ko: "Category",
    en: "Category",
  },
  CDMS_STUDY_IP_AUTO_CONDITION_REQUEST_PLACEHOLDER_IP_CATEGORY: {
    ko: "IP Category",
    en: "IP Category",
  },
  CDMS_STUDY_IP_AUTO_CONDITION_REQUEST_LABEL_MINIMUM_QUANTITY_BY_PRODUCT: {
    ko: "의약품 별 임계수량",
    en: "Minimum quantity by product",
  },
};

MvnCodeInitiate(CDMS_STUDY_IP_AUTO_CONDITION_LANG);
