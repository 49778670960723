import { CaseReportExportSpec, CaseReportExportSpecV1, CaseReportExportSpecV2 } from "./CaseReportExport.type";
import { CaseReportImportSpec } from "./CaseReportImport.type";
import { SafetyCaseSyncReserved } from "./CaseSync.dto";
import { LaString, UnionRight } from "../common/common.types";

export type SafetyCrfPageSpec = { isInitialPage?: boolean; sections: Array<SafetyCrfPageSectionSpec> };

export type SafetyCrfPageSectionDecoratableLabelStyle = {
  color?: "BLACK";
  align?: "LEFT" | "CENTER" | "RIGHT";
  background?: "WHITE";
};

export type SafetyCrfPageSectionDecoratableLabelSpec = {
  text: LaString;
  style?: SafetyCrfPageSectionDecoratableLabelStyle;
};

export type SafetyCrfPageSectionLabelSpec = LaString | SafetyCrfPageSectionDecoratableLabelSpec;

export type SafetyCrfPageSectionSpec = {
  id: string;
  label?: SafetyCrfPageSectionLabelSpec;
  items: Array<SafetyCrfItemSpec>;
};

export enum SafetyCrfItemReserved {
  WorldwideUniqueId = "WORLDWIDE_UNIQUE_ID",
  LatestRecognizedDate = "LATEST_RECOGNIZED_DATE",
  SubmissionDueAt = "SUBMISSION_DUE_AT",
  Resource = "RESOURCE",
  ProtocolNo = "PROTOCOL_NO",
  SubjectNo = "SUBJECT_NO",
  ProductName = "PRODUCT_NAME",
  ProductType = "PRODUCT_TYPE",
  PatientName = "PATIENT_NAME",
  ReactionEvent = "REACTION/EVENT",
  ReactionEventStartDate = "REACTION/EVENT_START_DATE",
  IsSerious = "IS_SERIOUS",
  Serious = "SERIOUS",
  IsBlinded = "IS_BLINDED",
  ReportCreatedTime = "REPORT_CREATED_TIME",
  SenderReportIdentifier = "SENDER_REPORT_IDENTIFIER",
}

export type CaseCreateReqReserved =
  | SafetyCrfItemReserved.WorldwideUniqueId
  | SafetyCrfItemReserved.LatestRecognizedDate
  | SafetyCrfItemReserved.Resource
  | SafetyCrfItemReserved.ProtocolNo
  | SafetyCrfItemReserved.SubjectNo
  | SafetyCrfItemReserved.ProductName
  | SafetyCrfItemReserved.ProductType
  | SafetyCrfItemReserved.PatientName
  | SafetyCrfItemReserved.ReactionEvent
  | SafetyCrfItemReserved.IsSerious;

export type SafetyCrfItemCommonReserved =
  | "WORLDWIDE_UNIQUE_ID"
  | "LATEST_RECOGNIZED_DATE"
  | "SUBMISSION_DUE_AT"
  | "RESOURCE"
  | "PROTOCOL_NO"
  | "SUBJECT_NO"
  | "PRODUCT_NAME"
  | "PRODUCT_TYPE"
  | "PATIENT_NAME"
  | "REACTION/EVENT"
  | "REACTION/EVENT_START_DATE"
  | "IS_SERIOUS"
  | "SERIOUS"
  | "IS_BLINDED"
  | "REPORT_CREATED_TIME"
  | "SENDER_REPORT_IDENTIFIER";

export type SafetyCrfItemType =
  | "TEXT"
  | "RELATED_TEXT"
  | "DATE"
  | "DATETIME"
  | "TIME"
  | "SINGLE_SELECT"
  | "DICTIONARY"
  | "SEQUENCE"
  | "CHECK"
  | "CONSTANT"
  | "APPENDABLE_TABLE"
  | "FOLDABLE_TABLE"
  | "AUTO_TEXT"
  | "FILE"
  | "HIDDEN"
  | "DELIMITER_TEXTS"
  | "ACCORDION_TABLE"
  | "HORIZONTAL_GROUP"
  | "DESCRIPTION";

export type SafetyCrfItemLabelDescriptionSpec = { text: LaString; display?: "BLOCK" };

export type SafetyCrfItemDecoratableLabelSpec = {
  text: LaString;
  style?: {
    color?: "BLACK";
    background?: "WHITE";
  };
  requiredVisibility?: boolean;
  description?: LaString | SafetyCrfItemLabelDescriptionSpec;
};

export type SafetyCrfItemLabelSpec = LaString | SafetyCrfItemDecoratableLabelSpec;
export type SafetyCrfItemTableLabelSpec = LaString;

export class CrfItemCriteria {
  static TABLE_SEQUENCE_FIXED_WIDTH = 60;
  static DATE_ITEM_FORMAT_DELIMITER = ["/", "-", "."];
}

export type SafetyCrfCaptureData = {
  id: SafetyCrfCaptureItemIdSpec;
  val: SafetyCrfCaptureItemDataSpec;
};

export type SafetyCrfCaptureItemIdSpec = {
  pageId: string;
  sectionId: string;
  itemId: string;
  itemSeq: number;
  syncSeq?: number;
};

export type SafetyCrfCaptureItemDataRelatedSpec = {
  pageId: string;
  sectionId: string;
  tableId: string;
  itemSeq: number;
};

export type SafetyCrfCaptureValueSpec = string | number | null;

export type SafetyCrfCaptureItemDataSpec = {
  dbVal: SafetyCrfCaptureValueSpec;
  calcVal?: SafetyCrfCaptureValueSpec;
  removed?: boolean;
  related?: SafetyCrfCaptureItemDataRelatedSpec;
};

export type SafetyCrfItemChildrenAlign = "LEFT" | "RIGHT";
export type SafetyCrfItemChildrenDisplay = "BLOCK" | "INLINE";
export interface SafetyCrfItemChildrenSpec {
  items: Array<SafetyCrfAtomicItemSpec>;
  align?: SafetyCrfItemChildrenAlign;
  display?: SafetyCrfItemChildrenDisplay;
}

export type SafetyCrfItemCommonSpec = {
  id: string;
  label: SafetyCrfItemLabelSpec;
  itemSeq?: number;
  required?: boolean | SafetyConditionalRequiredSpec;
  availability?: SafetyAvailabilitySpec;
  visibility?: SafetyVisibilitySpec;
  capturable?: boolean | SafetyConditionalCapturableSpec;
  readonly?: boolean | SafetyConditionalReadonlySpec;
  size?: "NARROW" | "BASE" | "WIDE" | "WIDER";
  children?: SafetyCrfItemChildrenSpec;
  miscItemVisibility?: SafetyMiscItemVisibilitySpec;
  reserved?: SafetyCrfItemCommonReserved;
  syncReserved?:
    | SafetyCaseSyncReserved
    | {
        reserved: SafetyCaseSyncReserved;
        bindTo: {
          pageId: string;
          sectionId: string;
          itemId: string;
          itemSeq: number;
          value: SafetyCrfCaptureValueSpec;
        };
      };
  description?: SafetyCrfItemDescriptionSpec | Array<SafetyCrfItemDescriptionSpec>;
  tooltip?: { position: "VALUE"; message?: LaString };
  blind?: SafetyBlindSpec;
  disabled?: SafetyDisabledSpec;
  events?: Array<SafetyCrfItemEventTypes>;
  validators?: Array<SafetyValidatorSpec>;
};

export type SafetyCrfItemConditionalDescriptionSpec = {
  message: LaString;
  replace?: {
    [key: string]:
      | { reserved: "ORG_DICT_LICENSE_MEDDRA_EXPIRY_AT" | "ORG_DICT_LICENSE_WHODD_EXPIRY_AT" }
      | { reserved: "CASE_REPORT_DICTIONARY_INFO"; key: SafetyCrfDictionaryType };
  };
  condition?: SafetyLogicalConditionalExpressionSpec | SafetyRelationalComparableExpressionSpec;
};

export type SafetyCrfItemDescriptionIconType = "ALERT_CIRCLE" | "ALERT_HEXAGON";
export type SafetyCrfItemDescriptionIconColor = "GREY" | "RED";
export type SafetyCrfItemDescriptionIconAlign = "LEFT";

export type SafetyCrfItemDescriptionIconSpec = {
  type: SafetyCrfItemDescriptionIconType;
  color: SafetyCrfItemDescriptionIconColor;
  align: SafetyCrfItemDescriptionIconAlign;
};

export type SafetyCrfItemDescriptionTextColor = "PRIMARY" | "RED" | "GRAY";
export type SafetyCrfItemDescriptionTextAlign = "LEFT" | "RIGHT" | "CENTER";

export type SafetyCrfItemDescriptionSpec = {
  text: LaString | Array<SafetyCrfItemConditionalDescriptionSpec>;
  icon?: SafetyCrfItemDescriptionIconSpec;
  visibility?: SafetyVisibilitySpec;
  style?: {
    fontSize?: number;
    color?: SafetyCrfItemDescriptionTextColor;
    align?: SafetyCrfItemDescriptionTextAlign;
  };
  tooltip?: LaString;
};

export type SafetyMiscItemVisibilitySpec = {
  comment?: boolean | SafetyConditionalMiscItemVisibilitySpec;
  auditTrail?: boolean | SafetyConditionalMiscItemVisibilitySpec;
  remove?: boolean | SafetyConditionalMiscItemVisibilitySpec;
};

export type SafetyConditionalMiscItemVisibilitySpec = SafetyLogicalConditionalExpressionSpec | SafetyRelationalComparableExpressionSpec;

export type SafetyConditionalReadonlySpec = SafetyLogicalConditionalExpressionSpec | SafetyRelationalComparableExpressionSpec;

export type SafetyVisibilitySpec = SafetyLogicalConditionalExpressionSpec | SafetyRelationalComparableExpressionSpec;

export type SafetyConditionalRequiredSpec = SafetyLogicalConditionalExpressionSpec | SafetyRelationalComparableExpressionSpec;

export type SafetyConditionalCapturableSpec = SafetyLogicalConditionalExpressionSpec | SafetyRelationalComparableExpressionSpec;

export type SafetyDisabledSpec = SafetyLogicalConditionalExpressionSpec | SafetyRelationalComparableExpressionSpec;

export type SafetyAvailabilitySpec = SafetyLogicalConditionalExpressionSpec | SafetyRelationalComparableExpressionSpec;

export type SafetyBlindSpec = SafetyLogicalConditionalExpressionSpec | SafetyRelationalComparableExpressionSpec;

export type SafetyValiditySpec = SafetyLogicalConditionalExpressionSpec | SafetyRelationalComparableExpressionSpec;

export type SafetyValidatorSpec = Partial<Pick<SafetyCrfItemCommonSpec, "required" | "availability">> & {
  id: string;
  validity?: SafetyValiditySpec;
};

export type SafetyConditionalReferenceValueSpec = { reserved: "RELATED_ITEM_ALIAS"; alias: string; format?: "NUMBER" };

export type SafetyConditionalReferenceSpec = {
  pageId: string;
  sectionId: string;
  itemId: string;
  /** NOTE. CURRENT : 현재 Item Seq를 필요로 할 때, ANY : Appendable Table에서 모든 Item Seq를 필요로 할 때 사용 */
  itemSeq: number | { reserved: "CURRENT" } | { reserved: "ANY" } | { reserved: "ALIAS"; key: string };
  value?: SafetyConditionalReferenceValueSpec;
  alias?: string;
};

export type SafetyReferenceItemIdSpec = UnionRight<SafetyCrfCaptureItemIdSpec, { itemSeq: number | { reserved: "ANY" } }>;

export type SafetyRelationalComparableExpressionSpec = {
  left:
    | SafetyConditionalReferenceSpec
    | { reserved: "WORKSPACE_CONFIG"; key: "caseIdentifierIsAuto" | "caseIdentifierModifiable" }
    | { reserved: "ORG_DICT_LICENSE_MEDDRA_IS_VALID" }
    | { reserved: "ORG_DICT_LICENSE_WHODD_IS_VALID" }
    | { reserved: "PRIVILEGE" }
    | { reserved: "REPORT_SEQ" };
  operator: "=" | ">" | ">=" | "<" | "<=" | "!=";
  right: SafetyCrfCaptureValueSpec | Array<SafetyCrfCaptureValueSpec>;
};

export type SafetyLogicalConditionalExpressionSpec = {
  operator: "AND" | "OR";
  expr: Array<SafetyLogicalConditionalExpressionSpec | SafetyRelationalComparableExpressionSpec>;
};

export type SafetyManageableCrfItemSpec = Pick<
  SafetyCrfItemCommonSpec,
  "label" | "itemSeq" | "availability" | "required" | "capturable" | "readonly" | "blind" | "validators" | "syncReserved"
> & {
  pageId: string;
  sectionId: string;
  itemId: string;
  type: SafetyCrfItemType;
  original: SafetyCrfItemSpec | SafetyCrfAppendableItemSpec | SafetyCrfFoldableItemSpec | SafetyCrfAccordionRowItemSpec;
  parent?: SafetyManageableCrfItemSpec;
  reserved?: SafetyCrfItemCommonReserved | SafetyCrfDictionaryCaptureReserved | SafetyCrfFileItemCaptureReserved;
};

export enum TextItemCharFormatInterface {
  EnglishLetter = "ENGLISH_LETTER",
  Number = "NUMBER",
  SpecialCharacter = "SPECIAL_CHARACTER",
}

export type SafetyCrfTextItemFormat =
  | {
      type: "NATURAL_NUMBER";
      minval?: number;
      maxval?: number;
      minlen?: number;
      maxlen?: number;
    }
  | {
      type: "DECIMAL";
      digit: {
        maxlen: number;
        includeDotText: boolean;
      };
      integerPart: {
        minlen: number;
        maxlen: number;
      };
      fractionPart: {
        minlen: number;
        maxlen?: number;
        roundPosition?: number;
      };
      minval?: number;
      maxval?: number;
    }
  | {
      type: "CHAR";
      interface?: Array<TextItemCharFormatInterface>;
      multiline?: boolean | { rows?: number; autoAdjustHeight?: boolean };
      minlen?: number;
      maxlen?: number;
    };

export type SafetyCrfTextItemSpec = SafetyCrfItemCommonSpec & {
  type: "TEXT";
  uiPrefix?: string;
  uiSuffix?: string;
  dbPrefix?: string;
  dbSuffix?: string;
  placeholder?: LaString;
  format?: SafetyCrfTextItemFormat;
};

export type SafetyCrfRelatedTextItemRelatedField = SafetyCrfRelatedTextItemRelatedItemField | SafetyCrfRelatedTextItemRelatedSeqField;

export interface SafetyCrfRelatedTextItemRelatedItemField {
  type: "ITEM";
  id: string;
  label: LaString;
  alias?: string;
}
export interface SafetyCrfRelatedTextItemRelatedSeqField {
  type: "SEQUENCE";
  alias?: string;
}

export type SafetyCrfRelatedTextItemSpec = SafetyCrfItemCommonSpec & {
  type: "RELATED_TEXT";
  related: {
    type: "APPENDABLE_TABLE" | "FOLDABLE_TABLE" | "ACCORDION_TABLE";
    id: {
      pageId: string;
      sectionId: string;
      itemId: string;
    };
    fields: Array<SafetyCrfRelatedTextItemRelatedField>;
    value: string;
  };
};

export type SafetyCrfHorizontalGroupItemSpec = Omit<SafetyCrfItemCommonSpec, "id" | "label"> & {
  type: "HORIZONTAL_GROUP";
  items: Array<SafetyCrfAtomicItemSpec>;
};

export type SafetyCrfItemCodeCommonSpec = {
  uiVal: LaString | null;
  dbVal: SafetyCrfCaptureValueSpec;
  calcVal?: SafetyCrfCaptureValueSpec;
  relatedText?: SafetyCrfRelatedTextItemSpec;
  isDefault?: boolean;
};

export type SafetyCrfItemCodeCheckSpec = { id: string } & SafetyCrfItemCodeCommonSpec;

export type SafetyCrfItemSingSelectLayout = "RADIO" | "DROPDOWN" | "CHECKBOX" | "PICKER";
export type SafetyCrfSingleSelectItemSpec = SafetyCrfItemCommonSpec & {
  type: "SINGLE_SELECT";
  direction?: "HORIZONTAL" | "VERTICAL";
  align?: "LEFT" | "RIGHT";
  itemCode: {
    null: { uiVal: null; dbVal: null };
    codes: Array<SafetyCrfItemCodeCommonSpec>;
  };
  layout: SafetyCrfItemSingSelectLayout;
};

export enum SafetyItemEventPublishValue {
  DbVal = "DB_VAL",
}

export enum SafetyItemEventSubFuncMappedValReserved {
  CapturedVal = "CAPTURED_VAL",
  EventValue = "EVENT_VALUE",
}

export enum SafetyItemEventSubFuncMappingCondReserved {
  CapturedDbVal = "CAPTURED_DB_VAL",
  EventValue = "EVENT_VALUE",
}

type SafetyItemEventSubFuncMappedValSpec = SafetyCrfCaptureItemDataSpec | { reserved: SafetyItemEventSubFuncMappedValReserved };

export enum SafetyItemEventTrigger {
  OnValueChangeAtEntryScreen = "ON_VALUE_CHANGE_AT_ENTRY_SCREEN",
  OnValueInitialAtEntryScreen = "ON_VALUE_INITIAL_AT_ENTRY_SCREEN",
}

export enum SafetyItemEventFunction {
  TryCapture = "TRY_CAPTURE",
  TryCaptureIfSameItemSeq = "TRY_CAPTURE_IF_SAME_ITEM_SEQ",
}

export type SafetyItemEventValueType = SafetyCrfCaptureValueSpec;

export type SafetyItemEventSubFuncMappingCondSpec = {
  left: { reserved: SafetyItemEventSubFuncMappingCondReserved };
  operator: "=" | ">" | ">=" | "<" | "<=" | "!=";
  right: SafetyCrfCaptureValueSpec | Array<SafetyCrfCaptureValueSpec>;
};

export type SafetyItemEventSubFuncMappingCondExprSpec = {
  operator: "AND" | "OR";
  expr: Array<SafetyItemEventSubFuncMappingCondExprSpec | SafetyItemEventSubFuncMappingCondSpec>;
};

export type SafetyItemEventSubFuncMappingSpec = {
  condition: SafetyItemEventSubFuncMappingCondExprSpec | SafetyItemEventSubFuncMappingCondSpec;
  value: SafetyItemEventSubFuncMappedValSpec;
};

export type SafetyItemEventTypePublish = {
  type: "PUBLISH";
  name: string;
  trigger: { type: SafetyItemEventTrigger };
  value: { type: SafetyItemEventPublishValue };
};

export type SafetyItemEventTypeSubscribe = {
  type: "SUBSCRIBE";
  name: string;
  function: {
    type: SafetyItemEventFunction;
    mapping?: Array<SafetyItemEventSubFuncMappingSpec>;
  };
};

export type SafetyCrfItemEventTypes = SafetyItemEventTypePublish | SafetyItemEventTypeSubscribe;

export type SafetyCrfCheckItemSpec = Omit<SafetyCrfItemCommonSpec, "id"> & {
  type: "CHECK";
  direction?: "HORIZONTAL" | "VERTICAL" | { type: "HORIZONTAL"; break: number };
  itemCode: {
    codes: Array<SafetyCrfItemCodeCheckSpec>;
  };
};

export type SafetyCrfDateItemFormat = "YYYY/MM/DD" | "YYYY/MM" | "YYYY-MM-DD" | "YYYY-MM";

export type SafetyCrfDateRangeBindElement = {
  value: number | "TODAY" | SafetyConditionalReferenceSpec;
  conditional: {
    left: SafetyConditionalReferenceSpec;
    operator: "=" | ">" | ">=" | "<" | "<=" | "!=";
    right: SafetyCrfCaptureValueSpec | Array<SafetyCrfCaptureValueSpec>;
  };
};

export type SafetyCrfDateItemDateRangeSpec = number | "TODAY" | Array<SafetyCrfDateRangeBindElement>;

export type SafetyCrfDateItemSpec = SafetyCrfItemCommonSpec & {
  type: "DATE";
  format?: SafetyCrfDateItemFormat;
  typeable?: true;
  minDate: SafetyCrfDateItemDateRangeSpec;
  maxDate: SafetyCrfDateItemDateRangeSpec;
  unknowable?: {
    all?: boolean;
    year: boolean;
    month: boolean;
    monthDay: boolean;
    day: boolean;
  };
};

export type SafetyCrfDateTimeItemSpec = SafetyCrfItemCommonSpec & {
  type: "DATETIME";
  minDate: number | "TODAY";
  maxDate: number | "TODAY";
};

export type SafetyCrfDelimiterTextsSpec = SafetyCrfItemCommonSpec & {
  type: "DELIMITER_TEXTS";
  delimiter: string;
  format?: SafetyCrfTextItemFormat;
};

export enum SafetyCrfDictionaryType {
  Meddra = "MEDDRA",
  WhoDD_C3 = "WHODD_C3",
  WhoDD_C3_Sun = "WHODD_C3_SUN",
  MfdsIngredient = "MFDS_INGREDIENT",
  MfdsDrug = "MFDS_DRUG",
  EdqmStandardTerm_PDF = "EDQM_STANDARD_TERM_PHARMACEUTICAL_DOSE_FORM",
  EdqmStandardTerm_ROA = "EDQM_STANDARD_TERM_ROUTE_OF_ADMINISTRATION",
}

export type SafetyCrfDictionaryCaptureReserved =
  | SafetyCrfDictionaryMeddraTermSpecReserved
  | SafetyCrfDictionaryWhoDDC3TermSpecReserved
  | SafetyCrfDictionaryWhoDDC3SubstanceTermSpecReserved
  | SafetyCrfDictionaryMfdsIngredientTermSpecReserved
  | SafetyCrfDictionaryMfdsDrugTermSpecReserved
  | SafetyCrfDictionaryEdqmStandardTermPharmaceuticalDoseFormReserved
  | SafetyCrfDictionaryEdqmStandardTermRouteOfAdministrationReserved;

export type SafetyCrfDictionaryMeddraTermSpecReserved =
  | "VERSION"
  | "SOC_CODE"
  | "SOC_NAME"
  | "HLGT_CODE"
  | "HLGT_NAME"
  | "HLT_CODE"
  | "HLT_NAME"
  | "PT_CODE"
  | "PT_NAME"
  | "LLT_CODE"
  | "LLT_NAME";

export type SafetyCrfDictionaryMeddraTermSpec = {
  type: SafetyCrfDictionaryType.Meddra;
  capture: Array<{
    itemId: string;
    reserved: SafetyCrfDictionaryMeddraTermSpecReserved;
  }>;
};

export type SafetyCrfDictionaryWhoDDC3TermSpecReserved = "VERSION" | "MPID" | "DRUG_NAME" | "DRUG_CODE";
export type SafetyCrfDictionaryWhoDDC3TermSpec = {
  type: SafetyCrfDictionaryType.WhoDD_C3;
  enableSorting?: boolean;
  capture: Array<{
    itemId: string;
    reserved: SafetyCrfDictionaryWhoDDC3TermSpecReserved;
  }>;
};

export type SafetyCrfDictionaryWhoDDC3SubstanceTermSpecReserved = "VERSION" | "CAS_NUMBER" | "SUBSTANCE_NAME";
export type SafetyCrfDictionaryWhoDDC3SubstanceTermSpec = {
  type: SafetyCrfDictionaryType.WhoDD_C3_Sun;
  capture: Array<{ itemId: string; reserved: SafetyCrfDictionaryWhoDDC3SubstanceTermSpecReserved }>;
};

export type SafetyCrfDictionaryMfdsIngredientTermSpecReserved = "VERSION" | "INGREDIENT_CODE" | "INGREDIENT_NAME" | "INGREDIENT_NAME_EN";

export type SafetyCrfDictionaryMfdsIngredientTermSpec = {
  type: SafetyCrfDictionaryType.MfdsIngredient;
  capture: Array<{
    itemId: string;
    reserved: SafetyCrfDictionaryMfdsIngredientTermSpecReserved;
  }>;
};

export type SafetyCrfDictionaryMfdsDrugTermSpecReserved = "VERSION" | "DRUG_CODE" | "DRUG_NAME";
export type SafetyCrfDictionaryMfdsDrugTermSpec = {
  type: SafetyCrfDictionaryType.MfdsDrug;
  capture: Array<{
    itemId: string;
    reserved: SafetyCrfDictionaryMfdsDrugTermSpecReserved;
  }>;
};

export type SafetyCrfDictionaryEdqmStandardTermPharmaceuticalDoseFormReserved =
  | "VERSION"
  | "EDQM_CODE"
  | "TERM"
  | "AME"
  | "ISI"
  | "RCA"
  | "TRA";

export type SafetyCrfDictionaryEdqmStandardTermPharmaceuticalDoseFormSpec = {
  type: SafetyCrfDictionaryType.EdqmStandardTerm_PDF;
  capture: Array<{
    itemId: string;
    reserved: SafetyCrfDictionaryEdqmStandardTermPharmaceuticalDoseFormReserved;
  }>;
};

export type SafetyCrfDictionaryEdqmStandardTermRouteOfAdministrationReserved = "VERSION" | "EDQM_CODE" | "TERM";

export type SafetyCrfDictionaryEdqmStandardTermRouteOfAdministrationSpec = {
  type: SafetyCrfDictionaryType.EdqmStandardTerm_ROA;
  capture: Array<{
    itemId: string;
    reserved: SafetyCrfDictionaryEdqmStandardTermRouteOfAdministrationReserved;
  }>;
};

export type SafetyCrfDictionaryItemTermSpec =
  | SafetyCrfDictionaryMeddraTermSpec
  | SafetyCrfDictionaryWhoDDC3TermSpec
  | SafetyCrfDictionaryWhoDDC3SubstanceTermSpec
  | SafetyCrfDictionaryMfdsIngredientTermSpec
  | SafetyCrfDictionaryMfdsDrugTermSpec
  | SafetyCrfDictionaryEdqmStandardTermPharmaceuticalDoseFormSpec
  | SafetyCrfDictionaryEdqmStandardTermRouteOfAdministrationSpec;

export type SafetyCrfAutoTextItemBindElement = {
  value: string | number | SafetyConditionalReferenceSpec;
  conditional: {
    left: SafetyConditionalReferenceSpec;
    operator: "=" | ">" | ">=" | "<" | "<=" | "!=";
    right: SafetyCrfCaptureValueSpec | Array<SafetyCrfCaptureValueSpec>;
  };
};

export type SafetyCrfFileItemCaptureReserved = "FILE_KEY" | "FILE_BUCKET";

export type SafetyCrfFileItemCaptureSpec = {
  itemId: string;
  reserved: SafetyCrfFileItemCaptureReserved;
};

export type SafetyCrfAutoTextItemSpec = SafetyCrfItemCommonSpec & {
  type: "AUTO_TEXT";
  bind?: Array<SafetyCrfAutoTextItemBindElement>;
};

export type SafetyCrfDictionaryItemSpec = SafetyCrfItemCommonSpec & {
  type: "DICTIONARY";
  dictionary: SafetyCrfDictionaryItemTermSpec;
};

export type SafetyCrfSequenceItemSpec = SafetyCrfItemCommonSpec & {
  type: "SEQUENCE";
  uiPrefix?: string;
  uiSuffix?: string;
};

export type SafetyCrfDescriptionItemSpec = Omit<SafetyCrfItemCommonSpec, "id" | "label" | "description"> & {
  type: "DESCRIPTION";
  description: LaString;
  style?: "BOX";
};

export type SafetyCrfConstantItemSpec = SafetyCrfItemCommonSpec & {
  type: "CONSTANT";
  value: string | { reserved: "WORKSPACE_DICTIONARY_INFO"; key: SafetyCrfDictionaryType };
};

export type SafetyCrfFileItemType = { name: string; key: string; bucket: string };

export type SafetyCrfFileItemSpec = SafetyCrfItemCommonSpec & {
  type: "FILE";
  capture: Array<SafetyCrfFileItemCaptureSpec>;
};

export type SafetyCrfHiddenItemConditionalValueCaclulateCaptureItemIdSpec = UnionRight<
  SafetyCrfCaptureItemIdSpec,
  { itemSeq: number | { reserved: "CURRENT" } }
>;

export type SafetyCrfHiddenItemCalculateOperandSpec = SafetyCrfHiddenItemConditionalValueCaclulateCaptureItemIdSpec | number;

export type SafetyCrfHiddenItemCalculateValueSpec = {
  reserved: "CALCULATE";
  formula: {
    left: SafetyCrfHiddenItemCalculateOperandSpec;
    operator: "*" | "+" | "-" | "/";
    right: SafetyCrfHiddenItemCalculateOperandSpec;
  };
};

export type SafetyCrfHiddenItemConditionalValueSpec = {
  condition: SafetyConditionalRequiredSpec;
  value: SafetyCrfCaptureItemDataSpec | SafetyCrfCaptureItemIdSpec | SafetyCrfHiddenItemCalculateValueSpec;
};

export type SafetyCrfHiddenItemSpec = Omit<SafetyCrfItemCommonSpec, "label"> & {
  type: "HIDDEN";
  value: SafetyCrfCaptureItemDataSpec | Array<SafetyCrfHiddenItemConditionalValueSpec>;
};

export type SafetyCrfAccordionTableItemSpec = Omit<SafetyCrfItemCommonSpec, "label"> & {
  type: "ACCORDION_TABLE";
  label?: SafetyCrfItemTableLabelSpec;
  headers: Array<SafetyCrfAccordionRowItemSpec>;
  rows: Array<SafetyCrfAccordionRowItemSpec>;
};

export type SafetyCrfAccordionRowItemSpec =
  | SafetyCrfTextItemSpec
  | SafetyCrfRelatedTextItemSpec
  | SafetyCrfHorizontalGroupItemSpec
  | SafetyCrfSingleSelectItemSpec
  | SafetyCrfDateItemSpec
  | SafetyCrfDateTimeItemSpec
  | SafetyCrfSequenceItemSpec
  | SafetyCrfCheckItemSpec
  | SafetyCrfDictionaryItemSpec
  | SafetyCrfConstantItemSpec
  | SafetyCrfAutoTextItemSpec
  | SafetyCrfFileItemSpec
  | SafetyCrfHiddenItemSpec
  | SafetyCrfDelimiterTextsSpec;

export type SafetyCrfAppendableTableItemDirection = "VERTICAL" | "HORIZONTAL";
export type SafetyCrfAppendableTableItemSpec = Omit<SafetyCrfItemCommonSpec, "label"> & {
  type: "APPENDABLE_TABLE";
  direction: SafetyCrfAppendableTableItemDirection;
  cols: Array<SafetyCrfAppendableItemSpec>;
  label?: SafetyCrfItemTableLabelSpec;
  breaks?: Array<number>;
};

export type SafetyCrfFoldableTableItemSpec = Omit<SafetyCrfItemCommonSpec, "label"> & {
  type: "FOLDABLE_TABLE";
  cols: Array<{ weight?: SafetyCrfTableItemWeightSpec } & (SafetyCrfFoldableColumnSpec | Array<SafetyCrfFoldableColumnSpec>)>;
  items: Array<SafetyCrfFoldableItemSpec>;
};

export type SafetyCrfTableItemWeightFixSpec = { val: number };
export type SafetyCrfTableItemWeightSpec = number | SafetyCrfTableItemWeightFixSpec;

type SafetyCrfAppendableItemAttrSpec = {
  weight: SafetyCrfTableItemWeightSpec;
  style?: {
    color?: "BLACK";
    background?: "WHITE";
  };
};

export type SafetyCrfTextAppendableItemSpec = SafetyCrfTextItemSpec & SafetyCrfAppendableItemAttrSpec;
export type SafetyCrfSequenceAppendableItemSpec = SafetyCrfSequenceItemSpec & SafetyCrfAppendableItemAttrSpec;
export type SafetyCrfSingleSelectAppendableItemSpec = SafetyCrfSingleSelectItemSpec & SafetyCrfAppendableItemAttrSpec;
export type SafetyCrfDictionaryAppendableItemSpec = SafetyCrfDictionaryItemSpec & SafetyCrfAppendableItemAttrSpec;
export type SafetyCrfDateItemAppendableSpec = SafetyCrfDateItemSpec & SafetyCrfAppendableItemAttrSpec;
export type SafetyCrfDateTimeItemAppendableSpec = SafetyCrfDateTimeItemSpec & SafetyCrfAppendableItemAttrSpec;
export type SafetyCrfCheckItemAppendableSpec = SafetyCrfCheckItemSpec & SafetyCrfAppendableItemAttrSpec;
export type SafetyCrfConstantAppendableItemSpec = SafetyCrfConstantItemSpec & SafetyCrfAppendableItemAttrSpec;
export type SafetyCrfRelatedTextAppendableItemSpec = SafetyCrfRelatedTextItemSpec & SafetyCrfAppendableItemAttrSpec;
export type SafetyCrfAutoTextAppendableItemSpec = SafetyCrfAutoTextItemSpec & SafetyCrfAppendableItemAttrSpec;
export type SafetyCrfFileAppendableItemSpec = SafetyCrfFileItemSpec & SafetyCrfAppendableItemAttrSpec;

export type SafetyCrfAppendableItemSpec =
  | SafetyCrfTextAppendableItemSpec
  | SafetyCrfRelatedTextAppendableItemSpec
  | SafetyCrfSequenceAppendableItemSpec
  | SafetyCrfSingleSelectAppendableItemSpec
  | SafetyCrfDictionaryAppendableItemSpec
  | SafetyCrfDateItemAppendableSpec
  | SafetyCrfDateTimeItemAppendableSpec
  | SafetyCrfCheckItemAppendableSpec
  | SafetyCrfConstantAppendableItemSpec
  | SafetyCrfAutoTextAppendableItemSpec
  | SafetyCrfFileAppendableItemSpec;

export type SafetyCrfItemSpec =
  | SafetyCrfHorizontalGroupItemSpec
  | SafetyCrfDescriptionItemSpec
  | SafetyCrfAppendableTableItemSpec
  | SafetyCrfFoldableTableItemSpec
  | SafetyCrfHiddenItemSpec
  | SafetyCrfAtomicItemSpec
  | SafetyCrfAccordionTableItemSpec;

export type SafetyCrfAtomicItemSpec =
  | SafetyCrfTextItemSpec
  | SafetyCrfRelatedTextItemSpec
  | SafetyCrfSingleSelectItemSpec
  | SafetyCrfDateItemSpec
  | SafetyCrfDateTimeItemSpec
  | SafetyCrfCheckItemSpec
  | SafetyCrfSequenceItemSpec
  | SafetyCrfDictionaryItemSpec
  | SafetyCrfConstantItemSpec
  | SafetyCrfAutoTextItemSpec
  | SafetyCrfFileItemSpec
  | SafetyCrfDelimiterTextsSpec;

export type SafetyCrfFoldableColumnSpec = {
  refItemId: string | SafetyCrfFoldableColumnConditionalRefItemId;
  label?: LaString;
  mappedDisplayValue?: { [key: string]: string };
  weightBasis?: number;
  style?: {
    color?: "BLACK";
    background?: "WHITE";
  };
};

export type SafetyCrfFoldableColumnConditionalRefItemId = Array<{
  conditional: SafetyLogicalConditionalExpressionSpec | SafetyRelationalComparableExpressionSpec;
  itemId: string;
}>;

export type SafetyCrfFoldableItemSpec =
  | SafetyCrfTextItemSpec
  | SafetyCrfRelatedTextItemSpec
  | SafetyCrfHorizontalGroupItemSpec
  | SafetyCrfSingleSelectItemSpec
  | SafetyCrfDateItemSpec
  | SafetyCrfDateTimeItemSpec
  | SafetyCrfSequenceItemSpec
  | SafetyCrfCheckItemSpec
  | SafetyCrfDictionaryItemSpec
  | SafetyCrfConstantItemSpec
  | SafetyCrfAutoTextItemSpec
  | SafetyCrfFileItemSpec
  | SafetyCrfHiddenItemSpec
  | SafetyCrfDelimiterTextsSpec;

export type SafetyCrfPage = {
  page: string;
  label: LaString;
  order: number;
};

export type SafetyCrfPageGroup = {
  id: string;
  pages: Array<SafetyCrfPage>;
  label: LaString;
  order: number;
};

export type SafetyCrfStruct = {
  groups: Array<SafetyCrfPageGroup>;
};

export enum SafetyCrfEngineVersion {
  V1 = "V1",
  V2 = "V2",
}

export enum SafetyCrfUiVersion {
  V1 = "V1",
  V2 = "V2",
}

type SafetyCrfPayloadBase<EV extends SafetyCrfEngineVersion, UV extends SafetyCrfUiVersion, E extends CaseReportExportSpec> = {
  engineVersion: EV;
  uiVersion: UV;
  struct: SafetyCrfStruct;
  pages: { [key: string]: SafetyCrfPageSpec };
  imports?: Array<CaseReportImportSpec>;
  exports?: Array<E>;
};

export type SafetyCrfPayloadV1 = UnionRight<
  SafetyCrfPayloadBase<SafetyCrfEngineVersion.V1, SafetyCrfUiVersion, CaseReportExportSpecV1>,
  {
    engineVersion?: SafetyCrfEngineVersion.V1;
    uiVersion?: SafetyCrfUiVersion;
  }
>;

export type SafetyCrfPayloadV2 = UnionRight<
  SafetyCrfPayloadBase<SafetyCrfEngineVersion.V2, SafetyCrfUiVersion, CaseReportExportSpecV2>,
  {
    uiVersion?: SafetyCrfUiVersion;
    validatorIds?: Array<SafetyCrfE2B_R3ValidatorId>;
  }
>;

export type SafetyCrfPayload = SafetyCrfPayloadV1 | SafetyCrfPayloadV2;

export type SafetyCrfPayloadByVersion<T extends SafetyCrfEngineVersion> = T extends SafetyCrfEngineVersion.V1
  ? SafetyCrfPayloadV1
  : T extends SafetyCrfEngineVersion.V2
  ? SafetyCrfPayloadV2
  : never;

export enum E2B_R3_NullFlavor {
  NoInformation = "NI",
  Masked = "MSK",
  Unknown = "UNK",
  NotApplicable = "NA",
  AskedButUnknown = "ASKU",
  NotAsked = "NASK",
  NegativeInfinity = "NINF",
  PositiveInfinity = "PINF",
  Other = "OTH",
}

export type SafetyCrfE2B_R3ValidatorId = SafetyCrfE2B_R3_MFDSValidatorId | SafetyCrfE2B_R3_FDAValidatorId;

export enum SafetyCrfE2B_R3_MFDSValidatorId {
  MFDS = "MFDS",
}

export enum SafetyCrfE2B_R3_FDAValidatorId {
  FDA_PostMarket = "FDA_POST_MARKET",
  FDA_PreMarket = "FDA_PRE_MARKET",
}

export enum SafetyCrfE2BR3XMLRule {
  ICH = "ICH",
  MFDS = "MFDS",
  FDA = "FDA",
}

export enum SafetyCrfE2BR3ExcelRule {
  Standard = "Maven Standard",
}
