import { palette } from "@jnpmedi/grebob";

export interface Color {
  appContentBoxBackground: string;
  appContainerBackground: string;
  appSidebarColor: string;
  appSidebarColorHighlight: string;
  appSidebarBackground: string;
  appSidebarLogoColor: string;
  appColorActive: string;
  appLogoColor: string;
}

export const color: Color = {
  appContentBoxBackground: palette.white,
  appContainerBackground: palette.bg020,
  appSidebarColor: palette.black,
  appSidebarColorHighlight: palette.white,
  appSidebarBackground: palette.white,
  appSidebarLogoColor: palette["maven-blue-bright"],
  appColorActive: palette["maven-blue-dark"],
  appLogoColor: palette.blue040,
};
