/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeFE } from "./const";
import { MvnCode, MvnCodeInitiate } from "../lib/const";

export const STUDY_USER_LANG: {
  STUDY_USER_MANAGE_USER_BREADCRUMB: MvnCodeFE;
  STUDY_USER: MvnCodeFE;
  STUDY_USER_ADD_TITLE: MvnCodeFE;
  STUDY_USER_VIEW_TITLE: MvnCodeFE;
  STUDY_USER_DROPDOWN_PLACEHOLDER: MvnCodeFE;
  STUDY_USER_EMAIL_INPUT_PLACEHOLDER: MvnCodeFE;
  STUDY_USER_EMAIL_INVALID: MvnCodeFE;
  STUDY_USER_ADD_SUCC: MvnCodeFE;
  STUDY_USER_ADD_FAIL: MvnCodeFE;
  STUDY_USER_UPDATE_SUCC: MvnCodeFE;
  STUDY_USER_UPDATE_FAIL: MvnCodeFE;
  MANAGEMENT_USER_TABLE_COLUMN_CREATION_DATE: MvnCodeFE;
  MANAGEMENT_USER_TABLE_COLUMN_ISSUE_DATE: MvnCodeFE;
  MANAGEMENT_USER_TABLE_COLUMN_ACTIVE_DATE: MvnCodeFE;
  MANAGEMENT_USER_TABLE_COLUMN_LAST_SENT_DATE: MvnCodeFE;
  MANAGEMENT_USER_TABLE_COLUMN_ACTIVATE_STATUS: MvnCodeFE;
  MANAGEMENT_USER_TABLE_COLUMN_TERMINATE_DATE: MvnCodeFE;
  NOTIFY_STUDY_USER_ADDED_SUCC: MvnCodeFE;
  NOTIFY_STUDY_USER_ADDED_FAIL: MvnCodeFE;
  NOTIFY_STUDY_USER_ADDED_CONFIRM: MvnCodeFE;
  NOTIFY_STUDY_USER_ADDED_DIALOG_TITLE: MvnCodeFE;
  STUDY_USER_VIEW_BTN_DELETE: MvnCodeFE;
  STUDY_USER_VIEW_BTN_RESTORE: MvnCodeFE;
  STUDY_USER_VIEW_DELETE_DIALOG_TITLE: MvnCode;
  STUDY_USER_VIEW_DELETE_SUCC: MvnCodeFE;
  STUDY_USER_VIEW_DELETE_FAIL: MvnCodeFE;
  STUDY_USER_VIEW_RESTORE_DIALOG_TITLE: MvnCode;
  STUDY_USER_VIEW_RESTORE_SUCC: MvnCodeFE;
  STUDY_USER_VIEW_RESTORE_FAIL: MvnCodeFE;
  STUDY_USER_ACTIVATE_STATUS_INACTIVATED: MvnCodeFE;
  STUDY_USER_ACTIVATE_STATUS_ERROR: MvnCodeFE;
  STUDY_API_KEY_MANAGE: MvnCodeFE;
  STUDY_API_KEY_ACTIVATE_STATUS: MvnCodeFE;
  STUDY_API_KEY_STATUS_ENABLED: MvnCodeFE;
  STUDY_API_KEY_STATUS_DISABLED: MvnCodeFE;
  STUDY_API_KEY_EXPIRY_DATE: MvnCodeFE;
  STUDY_API_KEY_STATUS_ENABLED_BUTTON: MvnCodeFE;
  STUDY_API_KEY_STATUS_DISABLED_BUTTON: MvnCodeFE;
  STUDY_API_KEY_GENERATE_BUTTON: MvnCodeFE;
  STUDY_API_KEY_DELETE_BUTTON: MvnCodeFE;
  STUDY_API_KEY_DELETE_WARNING: MvnCodeFE;
  STUDY_API_KEY_STATUS_CHANGE_SUCC: MvnCodeFE;
  STUDY_API_KEY_STATUS_CHANGE_FAIL: MvnCodeFE;
  STUDY_API_KEY_GENERATE_SUCC: MvnCodeFE;
  STUDY_API_KEY_GENERATE_FAIL: MvnCodeFE;
  STUDY_API_KEY_DELETE_SUCC: MvnCodeFE;
  STUDY_API_KEY_DELETE_FAIL: MvnCodeFE;
} = {
  STUDY_USER_MANAGE_USER_BREADCRUMB: {
    ko: "등록된 Study 사용자를 확인하고 관리할 수 있습니다.",
    en: "Inquire and manage study Users.",
  },
  STUDY_USER: {
    ko: "사용자",
    en: "User",
  },
  STUDY_USER_ADD_TITLE: {
    ko: "사용자 추가",
    en: "Add New User",
  },
  STUDY_USER_VIEW_TITLE: {
    ko: "사용자 조회",
    en: "User",
  },
  STUDY_USER_DROPDOWN_PLACEHOLDER: {
    ko: "사용자를 선택해주세요.",
    en: "Please select a user.",
  },
  STUDY_USER_EMAIL_INPUT_PLACEHOLDER: {
    ko: "사용자 이메일을 입력해 주세요.",
    en: "Please enter the email address you would like to add.",
  },
  STUDY_USER_EMAIL_INVALID: {
    ko: "올바른 이메일을 입력해 주세요.",
    en: "Please enter a valid email address.",
  },
  STUDY_USER_ADD_SUCC: {
    ko: "신규 사용자({user})를 등록하였습니다.",
    en: "New user ({user}) has been registered successfully.",
  },
  STUDY_USER_ADD_FAIL: {
    ko: "신규 사용자 등록에 실패하였습니다. ({message})",
    en: "New user registeration has been failed. ({message})",
  },
  STUDY_USER_UPDATE_SUCC: {
    ko: "성공적으로 사용자({user}) 권한 정보를 갱신하였습니다.",
    en: "User({user}) privilege has been updated successfully.",
  },
  STUDY_USER_UPDATE_FAIL: {
    ko: "기존 Study 사용자 업데이트에 실패하였습니다. ({error})",
    en: "Failed to update Study User information. ({error})",
  },
  MANAGEMENT_USER_TABLE_COLUMN_CREATION_DATE: {
    ko: "계정 생성일",
    en: "Account Creation Date",
  },
  MANAGEMENT_USER_TABLE_COLUMN_ISSUE_DATE: {
    ko: "인증서 발급일",
    en: "Certificate Issue Date",
  },
  MANAGEMENT_USER_TABLE_COLUMN_ACTIVE_DATE: {
    ko: "계정 시작일",
    en: "Account Activation Date",
  },
  MANAGEMENT_USER_TABLE_COLUMN_LAST_SENT_DATE: {
    ko: "최근 메일 전송일",
    en: "Last sent Date",
  },
  MANAGEMENT_USER_TABLE_COLUMN_ACTIVATE_STATUS: {
    ko: "활성화 여부",
    en: "Activation Status",
  },
  MANAGEMENT_USER_TABLE_COLUMN_TERMINATE_DATE: {
    ko: "계정 만료일",
    en: "Termination Date",
  },
  NOTIFY_STUDY_USER_ADDED_CONFIRM: {
    ko: "메일을 전송하시겠습니까?",
    en: "Do you want to send the mail?",
  },
  NOTIFY_STUDY_USER_ADDED_SUCC: {
    ko: "메일 전송에 성공하였습니다.",
    en: "Mail has been sent successfully.",
  },
  NOTIFY_STUDY_USER_ADDED_FAIL: {
    ko: "메일 보내기에 실패했습니다. ({message})",
    en: "Failed to send the mail. ({message})",
  },
  NOTIFY_STUDY_USER_ADDED_DIALOG_TITLE: {
    ko: "이미 메일을 전송한 사용자입니다. 전송하시겠습니까?",
    en: "You have already sent the mail to this user. Do you want to send again?",
  },
  STUDY_USER_VIEW_BTN_DELETE: {
    ko: "Remove",
    en: "Remove",
  },
  STUDY_USER_VIEW_BTN_RESTORE: {
    ko: "Restore",
    en: "Restore",
  },
  STUDY_USER_VIEW_DELETE_DIALOG_TITLE: {
    ko: "Study User 삭제를 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to delete study user.",
  },
  STUDY_USER_VIEW_DELETE_SUCC: {
    ko: "사용자 삭제에 성공했습니다.",
    en: "User has been deleted successfully.",
  },
  STUDY_USER_VIEW_DELETE_FAIL: {
    ko: "사용자 삭제에 실패했습니다. ({message})",
    en: "User deletion has been failed. ({message})",
  },
  STUDY_USER_VIEW_RESTORE_DIALOG_TITLE: {
    ko: "User 복원을 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to restore study user.",
  },
  STUDY_USER_VIEW_RESTORE_SUCC: {
    ko: "사용자 복원에 성공했습니다.",
    en: "User has been restored successfully.",
  },
  STUDY_USER_VIEW_RESTORE_FAIL: {
    ko: "사용자 복원에 실패했습니다. ({message})",
    en: "User restoration has been failed. ({message})",
  },
  STUDY_USER_ACTIVATE_STATUS_INACTIVATED: {
    ko: "study 사용자가 비활성화 상태입니다. ({status})",
    en: "Your study user account has been inactivated ({status})",
  },
  STUDY_USER_ACTIVATE_STATUS_ERROR: {
    ko: "study에 접근할 수 없는 상태입니다. ({message})",
    en: "Failed to access study. ({message})",
  },
  STUDY_API_KEY_MANAGE: {
    ko: "API 연동 키 관리",
    en: "Manage API Integration Key",
  },
  STUDY_API_KEY_ACTIVATE_STATUS: {
    ko: "API 연동 키 활성화 여부",
    en: "API Integration Key Activation Status",
  },
  STUDY_API_KEY_STATUS_ENABLED: {
    ko: "사용중",
    en: "In use",
  },
  STUDY_API_KEY_STATUS_DISABLED: {
    ko: "사용 중지",
    en: "Paused",
  },
  STUDY_API_KEY_EXPIRY_DATE: {
    ko: "API 키 만료일",
    en: "API Key Expiry Date",
  },
  STUDY_API_KEY_STATUS_ENABLED_BUTTON: {
    ko: "사용 하기",
    en: "Resume",
  },
  STUDY_API_KEY_STATUS_DISABLED_BUTTON: {
    ko: "사용 중지",
    en: "Pause",
  },
  STUDY_API_KEY_GENERATE_BUTTON: {
    ko: "발급",
    en: "Generate",
  },
  STUDY_API_KEY_DELETE_BUTTON: {
    ko: "삭제",
    en: "Delete",
  },
  STUDY_API_KEY_DELETE_WARNING: {
    ko: "삭제시 해당 API 키는 사용이 불가합니다.",
    en: "After deletion, the API key can no longer be used.",
  },
  STUDY_API_KEY_STATUS_CHANGE_SUCC: {
    ko: "API 키 상태 변경에 성공했습니다.",
    en: "API key status changed successfully.",
  },
  STUDY_API_KEY_STATUS_CHANGE_FAIL: {
    ko: "API 키 상태 변경에 실패했습니다. ({message})",
    en: "Failed to change API key status.",
  },
  STUDY_API_KEY_GENERATE_SUCC: {
    ko: "API 키 발급에 성공했습니다.",
    en: "API key generated successfully.",
  },
  STUDY_API_KEY_GENERATE_FAIL: {
    ko: "API 키 발급에 실패했습니다. ({message})",
    en: "Failed to generate API key.",
  },
  STUDY_API_KEY_DELETE_SUCC: {
    ko: "API 키 삭제에 성공했습니다.",
    en: "API key deleted successfully.",
  },
  STUDY_API_KEY_DELETE_FAIL: {
    ko: "API 키 삭제에 실패했습니다. ({message})",
    en: "Failed to delete API key.",
  },
};

MvnCodeInitiate(STUDY_USER_LANG);
