import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_ELEMENT_LANG: {
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_ELEMENT_SAVE_SUCC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_ELEMENT_SAVE_FAIL: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_ELEMENT_EMPTY: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_ELEMENT_EMPTY_DESC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_MOVE_TO_SCHEDULE_CONFIRM: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_MOVE_TO_PAGE_CONFIRM: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_PROPERTY_DESC: MvnCodeFE;
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_TITLE_DESCRIPTION: MvnCodeFE;
} = {
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_ELEMENT_SAVE_SUCC: {
    ko: "Matrix 정보를 저장했습니다.",
    en: "You have saved matrix successfully.",
  },
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_ELEMENT_SAVE_FAIL: {
    ko: "Matrix 정보 저장에 실패하였습니다. ({message})",
    en: "Failed to save the matrix. ({message})",
  },
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_ELEMENT_EMPTY: {
    ko: "생성된 Schedule이 없습니다.",
    en: "No Schedule yet.",
  },
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_ELEMENT_EMPTY_DESC: {
    ko: "Schedule 먼저 생성해 주세요.",
    en: "Please build your schedule first.",
  },
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_MOVE_TO_SCHEDULE_CONFIRM: {
    ko: "스케줄 페이지로 이동합니다. 이 페이지를 나가시겠습니까?",
    en: "You will be directed to Schedule page. Are you sure you want to leave this page?",
  },
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_MOVE_TO_PAGE_CONFIRM: {
    ko: "({page})페이지로 이동합니다. 이 페이지를 나가시겠습니까?",
    en: "You will be directed to ({page}) page. Are you sure you want to leave this page?",
  },
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_PROPERTY_DESC: {
    ko: "Select cell to set property.",
    en: "Select cell to set property.",
  },
  BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_TITLE_DESCRIPTION: {
    ko: "해당 Visit에 Page를 설정할 수 있습니다.",
    en: "Add pages to the corresponding visits.",
  },
};

MvnCodeInitiate(BUILDER_STUDY_PAYLOAD_SCHEDULE_MATRIX_ELEMENT_LANG);
