import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const STUDY_CODING_LANG: {
  CODING_BREADCRUMB: MvnCodeFE;
  CODING_BATCH_PROPERTIES_DIALOG_HEADER: MvnCodeFE;
  CODING_CHECK_MIGRATABLE_FAIL: MvnCodeFE;
  CODING_ECRF_INFO_TITLE: MvnCodeFE;
  CODING_MIGRATE_SUCC: MvnCodeFE;
  CODING_MIGRATE_FAIL: MvnCodeFE;
  CODING_ENTER_SEARCH_TERM: MvnCodeFE;
  CODING_SEARCH_TERM_NOT_FOUND: MvnCodeFE;
  CODING_SEARCH_TERM_FAIL: MvnCodeFE;
  CODING_AUTO_DIALOG_TEXT: MvnCodeFE;
  CODING_AUTO_SUCC: MvnCodeFE;
  CODING_AUTO_FAIL: MvnCodeFE;
  CODING_FAST_SUCC: MvnCodeFE;
  CODING_FAST_FAIL: MvnCodeFE;
  CODING_FAST_OVERWRITE_HEADER: MvnCodeFE;
  CODING_FAST_OVERWRITE: MvnCodeFE;
  CODING_DELETE_DIALOG_TEXT: MvnCodeFE;
  CODING_DELETE_SUCC: MvnCodeFE;
  CODING_DELETE_FAIL: MvnCodeFE;
  CODING_ATC_CODE_SEARCH_FAIL: MvnCodeFE;
  CODING_MEDRA_CODE_SEARCH_FAIL: MvnCodeFE;
  CODING_WHODD_SEARCH_FAIL: MvnCodeFE;
  CODING_CHECK_CODING_SAVE: MvnCodeFE;
  CODING_DOWNLOAD_FAIL: MvnCodeFE;
  CODING_SUCC: MvnCodeFE;
  CODING_FAIL: MvnCodeFE;
  CODING_NOT_ENTERED: MvnCodeFE;
  CODING_BATCH_PROPERTIES_CHANGE_FAIL: MvnCodeFE;
  CODING_QUICK_CODING_DESCRIPTION_1: MvnCodeFE;
  CODING_QUICK_CODING_DESCRIPTION_2: MvnCodeFE;
  CODING_SYNC_SUCCESS: MvnCodeFE;
  CODING_SYNC_FAIL: MvnCodeFE;
  CODING_SYNC_CONFIRM_TEXT: MvnCodeFE;
  CODING_SYNC_CONFIRM_LABEL: MvnCodeFE;
  CODING_SYNC_CANCEL_LABEL: MvnCodeFE;
  CODING_SYNC_BTN_ALL: MvnCodeFE;
  CODING_SYNC_INQUIRY_SUCCESS: MvnCodeFE;
  CODING_SYNC_INQUIRY_FAIL: MvnCodeFE;
  CODING_SYNC_BTN_INQUIRY: MvnCodeFE;
  CODING_ITEM_DICTIONARY_PRODUCT_NAME: MvnCodeFE;
  CODING_ITEM_DICTIONARY_COMPOSITION: MvnCodeFE;
  CODING_ITEM_DICTIONARY_ATC_CODE: MvnCodeFE;
  CODING_ITEM_DICTIONARY_LICENSE_HOLDER: MvnCodeFE;
} = {
  CODING_BREADCRUMB: {
    ko: "병력, 증상, 약품 등에 표준코드를 할당할 수 있습니다.",
    en: "Allocate standard codes to medical history, symptoms, medications.",
  },
  CODING_BATCH_PROPERTIES_DIALOG_HEADER: {
    ko: "일괄속성변경",
    en: "Batch Properties",
  },
  CODING_CHECK_MIGRATABLE_FAIL: {
    ko: "Migrate 가능 여부 조회에 실패했습니다. ({message})",
    en: "The lookup for migrate availability has been failed. ({message})",
  },
  CODING_ECRF_INFO_TITLE: {
    ko: "eCRF 정보",
    en: "eCRF Info",
  },
  CODING_MIGRATE_SUCC: {
    ko: "Coding이 정상적으로 Migrate 되었습니다.",
    en: "Coding has been migrated successfully.",
  },
  CODING_MIGRATE_FAIL: {
    ko: "Coding Migrate에 실패했습니다. ({message})",
    en: "Coding migrate has been failed. ({message})",
  },
  CODING_ENTER_SEARCH_TERM: {
    ko: "검색어를 입력해 주세요.",
    en: "Please enter your search term.",
  },
  CODING_SEARCH_TERM_NOT_FOUND: {
    ko: "검색 결과가 없습니다.",
    en: "No results found.",
  },
  CODING_SEARCH_TERM_FAIL: {
    ko: "검색에 실패했습니다. ({message})",
    en: "Failed to search. ({message})",
  },
  CODING_AUTO_DIALOG_TEXT: {
    ko: "Auto Coding을 수행하시겠습니까?",
    en: "Do you want to Auto Coding?",
  },
  CODING_AUTO_SUCC: {
    ko: "{requestCount}개의 요청 건 중 {successCount}개의 요청이 성공했습니다.",
    en: "{successCount} out of {requestCount} request has been successfully.",
  },
  CODING_AUTO_FAIL: {
    ko: "Auto Coding에 실패했습니다. ({message})",
    en: "Auto Coding has been failed. ({message})",
  },
  CODING_FAST_SUCC: {
    ko: "{requestCount}개의 요청 건 중 {successCount}개의 요청이 성공했습니다.",
    en: "{successCount} out of {requestCount} request has been successfully.",
  },
  CODING_FAST_FAIL: {
    ko: "Coding 데이터 입력에 실패했습니다. ({message})",
    en: "Coding data enter has been failed. ({message})",
  },
  CODING_FAST_OVERWRITE_HEADER: {
    ko: "덮어쓰기",
    en: "Confirm Overwrite",
  },
  CODING_FAST_OVERWRITE: {
    ko: "이전에 입력된 데이터를 새로운 데이터로 덮어씁니다. 계속 하시겠습니까?",
    en: "The previous data will be overwritten with the current data. Are you sure you want to overwrite it?",
  },
  CODING_DELETE_DIALOG_TEXT: {
    ko: "코딩을 삭제하시겠습니까?",
    en: "Do you want to delete the coding?",
  },
  CODING_DELETE_SUCC: {
    ko: "Coding 정보 삭제에 성공했습니다",
    en: "Delete Coding has been successfully.",
  },
  CODING_DELETE_FAIL: {
    ko: "Coding 정보 삭제에 실패했습니다. ({message}))",
    en: "Delete Coding has been failed. ({message})",
  },
  CODING_ATC_CODE_SEARCH_FAIL: {
    ko: "ATC code 검색에 실패했습니다. ({message})",
    en: "ATC code search has been failed. ({message})",
  },
  CODING_MEDRA_CODE_SEARCH_FAIL: {
    ko: "MedDRA code 검색에 실패했습니다. ({message})",
    en: "MedDRA code search has failed. ({message})",
  },
  CODING_WHODD_SEARCH_FAIL: {
    ko: "WHODrug 정보 검색에 실패했습니다. ({message})",
    en: "WHODrug search has been failed. ({message})",
  },
  CODING_CHECK_CODING_SAVE: {
    ko: "코딩 사항을 저장하시겠습니까?",
    en: "Do you want to save this coding?",
  },
  CODING_DOWNLOAD_FAIL: {
    ko: "코딩 다운로드에 실패했습니다. ({error})",
    en: "Coding download has been failed. ({error})",
  },
  CODING_SUCC: {
    ko: "성공적으로 변경 사항을 저장했습니다.",
    en: "Changes have been saved successfully.",
  },
  CODING_FAIL: {
    ko: "변경 사항 저장에 실패하였습니다. ({message})",
    en: "Changes save has been failed. ({message})",
  },
  CODING_NOT_ENTERED: {
    ko: "코드가 입력되지 않았습니다.",
    en: "No code entered.",
  },
  CODING_BATCH_PROPERTIES_CHANGE_FAIL: {
    ko: "코딩 일괄속성 변경에 실패했습니다. ({error})",
    en: "Batch properties change has been failed. ({error})",
  },
  CODING_QUICK_CODING_DESCRIPTION_1: {
    ko: "체크된 모든 항목에 Coding 결과가 적용됩니다.",
    en: "Coding results are applied to all checked items.",
  },
  CODING_QUICK_CODING_DESCRIPTION_2: {
    ko: "Expectedness, Liver, Kidney 및 Comment를 지정하지 않으면 지정되지 않은 상태로 저장됩니다.",
    en: "If Expectedness, Liver, Kidney, and Comment are not specified, they are saved as unspecified.",
  },
  CODING_SYNC_SUCCESS: {
    ko: "Sync를 완료하였습니다.",
    en: "Sync has been successful.",
  },
  CODING_SYNC_FAIL: {
    ko: "Sync에 실패하였습니다. ({error})",
    en: "Sync has failed. ({error})",
  },
  CODING_SYNC_CONFIRM_TEXT: {
    ko: "정말 Sync 하시겠습니까?",
    en: "Please confirm to sync",
  },
  CODING_SYNC_CONFIRM_LABEL: {
    ko: "Sync",
    en: "Sync",
  },
  CODING_SYNC_CANCEL_LABEL: {
    ko: "취소",
    en: "Cancel",
  },
  CODING_SYNC_BTN_ALL: {
    ko: "Sync every codings(병력/이상반응/약물 모두)",
    en: "Sync every codings(PMH/SE/Med etc)",
  },
  CODING_SYNC_INQUIRY_SUCCESS: {
    ko: "조회에 성공했습니다.",
    en: "Inquiry has been successful.",
  },
  CODING_SYNC_INQUIRY_FAIL: {
    ko: "조회에 실패하였습니다. ({error})",
    en: "Inquiry has failed. ({error})",
  },
  CODING_SYNC_BTN_INQUIRY: {
    ko: "Sync 예정 목록 조회(병력/이상반응/약물 모두)",
    en: "Sync plan inquiries(PMH/SE/med etc)",
  },
  CODING_ITEM_DICTIONARY_PRODUCT_NAME: {
    ko: "상품명",
    en: "Product Name",
  },
  CODING_ITEM_DICTIONARY_COMPOSITION: {
    ko: "성분명",
    en: "Composition",
  },
  CODING_ITEM_DICTIONARY_ATC_CODE: {
    ko: "ATC 코드",
    en: "ATC Code",
  },
  CODING_ITEM_DICTIONARY_LICENSE_HOLDER: {
    ko: "업체명",
    en: "License Holder",
  },
};

MvnCodeInitiate(STUDY_CODING_LANG);
