/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeBE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const AFFILIATION_CODE: {
  AFFILIATION_NAME_DUPLICATED: MvnCodeBE;
  AFFILIATION_BUSINESS_REGISTRATION_NUMBER_DUPLICATED: MvnCodeBE;
  AFFILIATION_BUSINESS_REGISTRATION_NUMBER_REQUIRED_VALUE_MISSING: MvnCodeBE;
} = {
  // BE
  AFFILIATION_NAME_DUPLICATED: {
    status: 400,
    ko: "동일한 Affiliation 이름이 존재합니다. ({name})",
    en: "Affiliation name is already exist. ({name})",
  },
  AFFILIATION_BUSINESS_REGISTRATION_NUMBER_DUPLICATED: {
    status: 409,
    ko: "이미 등록되어 있는 사업자 등록번호입니다. ({businessRegistrationNo})",
    en: "This business registration number already exists. ({businessRegistrationNo})",
  },
  AFFILIATION_BUSINESS_REGISTRATION_NUMBER_REQUIRED_VALUE_MISSING: {
    status: 409,
    ko: "사업자 등록번호 입력 후 중복 확인해 주세요.",
    en: "Please enter business registration number.",
  },
};

MvnCodeInitiate(AFFILIATION_CODE);
