import styled from "@emotion/styled";
import { palette } from "@jnpmedi/grebob";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

const StyledMarkdown = styled("div")(() => ({
  "*": {
    whiteSpace: "break-spaces",
    wordBreak: "break-all",
    fontFamily: "Noto Sans KR",
    fontSize: "0.8rem",
    letterSpacing: "-0.2px",
    lineHeight: "140%",
  },
  "h1,h2,h3": {
    margin: "0.5rem 0 0 0",
  },
  h1: {
    fontSize: "1.1rem",
  },
  h2: {
    fontSize: "1rem",
  },
  h3: {
    fontSize: "0.9rem",
  },
  "ul,ol": {
    margin: "0",
    paddingLeft: "25px",
    lineHeight: "50%",
  },
  p: {
    margin: "0.5rem 0 0 0",
    code: {
      padding: "0 0.3rem",
      borderRadius: "0.3rem",
      backgroundColor: palette.grey020,
    },
  },
  pre: {
    width: "100%",
    margin: "0.5rem 0",
    code: {
      display: "block",
      width: "100%",
      padding: "0.8rem",
      borderRadius: "0.3rem",
      backgroundColor: palette.grey020,
    },
  },
  table: {
    borderCollapse: "collapse",
  },
  "td,th": {
    border: `1px solid ${palette.grey040}`,
    padding: "0.5rem",
  },
  blockquote: {
    margin: "0.5rem 0",
    padding: "0 0.8rem",
    borderRadius: "0 0.3rem 0.3rem 0",
    borderLeft: `4px solid ${palette["maven-blue-dark"]}`,
    backgroundColor: palette.grey020,
    p: {
      margin: 0,
    },
  },
  hr: {
    height: "1px",
  },
  "*:first-child": {
    marginTop: 0,
  },
  "*:last-child": {
    marginBottom: 0,
  },
}));

interface MarkdownProps {
  text: string;
}

export function Markdown(props: MarkdownProps) {
  return (
    <StyledMarkdown>
      <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
        {props.text}
      </ReactMarkdown>
    </StyledMarkdown>
  );
}
