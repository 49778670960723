import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_COMMON_LANG: {
  SAFETY_COMMON_PAGE_MOVEMENT_DETECTION_ALERT_MESSAGE: MvnCodeFE;
  SAFETY_COMMON_AUDIT_TRAIL_SYSTEM_ADMIN_ACTANT: MvnCodeFE;
  SAFETY_COMMON_SELECT: MvnCodeFE;
  SAFETY_COMMON_DATE_FORMAT: MvnCodeFE;
  SAFETY_COMMON_INPUT_PLACEHOLDER: MvnCodeFE;
  SAFETY_COMMON_UPDATE: MvnCodeFE;
  SAFETY_COMMON_CANCEL: MvnCodeFE;
  SAFETY_COMMON_DELETE: MvnCodeFE;
  SAFETY_COMMON_OK: MvnCodeFE;
} = {
  SAFETY_COMMON_PAGE_MOVEMENT_DETECTION_ALERT_MESSAGE: {
    ko: "저장되지 않은 자료가 있습니다. 페이지를 이동 하시겠습니까?",
    en: "You have unsaved data. Do you want to move the page?",
  },
  SAFETY_COMMON_AUDIT_TRAIL_SYSTEM_ADMIN_ACTANT: {
    ko: "System Admin",
    en: "System Admin",
  },
  SAFETY_COMMON_SELECT: {
    ko: "선택",
    en: "Select",
  },
  SAFETY_COMMON_DATE_FORMAT: {
    ko: "년(delimiter)월(delimiter)일",
    en: "YYYY(delimiter)MM(delimiter)DD",
  },
  SAFETY_COMMON_INPUT_PLACEHOLDER: {
    ko: "내용 입력",
    en: "Type here",
  },
  SAFETY_COMMON_UPDATE: {
    ko: "업데이트",
    en: "Update",
  },
  SAFETY_COMMON_CANCEL: {
    ko: "취소",
    en: "Cancel",
  },
  SAFETY_COMMON_DELETE: {
    ko: "삭제",
    en: "Delete",
  },
  SAFETY_COMMON_OK: {
    ko: "확인",
    en: "OK",
  },
};

MvnCodeInitiate(SAFETY_COMMON_LANG);
