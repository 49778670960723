import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_SITE_LANG: {
  CDMS_STUDY_SITE_DELETE_CONFIRM_DIALOG_TITLE: MvnCodeFE;
  CDMS_STUDY_SITE_DELETE_SUCC: MvnCodeFE;
  CDMS_STUDY_SITE_DELETE_FAIL: MvnCodeFE;
  CDMS_STUDY_SITE_RESTORE_HEADER: MvnCodeFE;
  CDMS_STUDY_SITE_RESTORE_DESC: MvnCodeFE;
  CDMS_STUDY_SITE_RESTORE_SUCC: MvnCodeFE;
  CDMS_STUDY_SITE_RESTORE_FAIL: MvnCodeFE;
  CDMS_STUDY_SITE_IRB_APPROVE_DATE: MvnCodeFE;
  CDMS_STUDY_SITE_CRF_PLACEHOLDER: MvnCodeFE;
  CDMS_STUDY_SITE_CRFS_EFFECTIVE_AT_MISSING: MvnCodeFE;
  CDMS_STUDY_SITE_CRFS_CRF_ID_MISSING: MvnCodeFE;
  CDMS_STUDY_SITE_NAME_MISSING: MvnCodeFE;
  CDMS_STUDY_SITE_CODE_MISSING: MvnCodeFE;
  CDMS_STUDY_SITE_MIGRATION_BUTTON: MvnCodeFE;
} = {
  CDMS_STUDY_SITE_DELETE_CONFIRM_DIALOG_TITLE: {
    ko: "Site 삭제를 위해 이메일과 비밀번호를 입력해 주세요.",
    en: "Please enter your email address and password to delete this site.",
  },
  CDMS_STUDY_SITE_DELETE_SUCC: {
    ko: "Site 삭제에 성공하였습니다.",
    en: "You have deleted site successfully.",
  },
  CDMS_STUDY_SITE_DELETE_FAIL: {
    ko: "Site 삭제에 실패하였습니다. ({message})",
    en: "Failed to delete site. ({message})",
  },
  CDMS_STUDY_SITE_RESTORE_HEADER: {
    ko: "Restore Site",
    en: "Restore Site",
  },
  CDMS_STUDY_SITE_RESTORE_DESC: {
    ko: "Site를 복원하시겠습니까?",
    en: "Are you sure you want to restore this site?",
  },
  CDMS_STUDY_SITE_RESTORE_SUCC: {
    ko: "Site가 성공적으로 복원되었습니다.",
    en: "You have restored site successfully.",
  },
  CDMS_STUDY_SITE_RESTORE_FAIL: {
    ko: "Site 복원에 실패했습니다. ({message})",
    en: "Failed to restore the site. ({message})",
  },
  CDMS_STUDY_SITE_IRB_APPROVE_DATE: {
    ko: "IRB Approve Date",
    en: "IRB Approve Date",
  },
  CDMS_STUDY_SITE_CRF_PLACEHOLDER: {
    ko: "Select CRF...",
    en: "Select CRF...",
  },
  CDMS_STUDY_SITE_CRFS_EFFECTIVE_AT_MISSING: {
    ko: "CRF Effective Date 값이 누락되었습니다.",
    en: "The CRF Effective Date is required.",
  },
  CDMS_STUDY_SITE_CRFS_CRF_ID_MISSING: {
    ko: "CRF 값이 누락되었습니다.",
    en: "The CRF is required.",
  },
  CDMS_STUDY_SITE_NAME_MISSING: {
    ko: "실시기관 값이 누락되었습니다.",
    en: "The Site Name is required.",
  },
  CDMS_STUDY_SITE_CODE_MISSING: {
    ko: "Code 값이 누락되었습니다.",
    en: "The Code is required.",
  },
  CDMS_STUDY_SITE_MIGRATION_BUTTON: {
    ko: "Save And Migrate",
    en: "Save And Migrate",
  },
};

MvnCodeInitiate(CDMS_STUDY_SITE_LANG);
