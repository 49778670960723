import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const BANKING_CODE: {
  BANKING_UNHANDLED_EXCEPTION: MvnCodeBE;
  BANKING_SYSTEM_CHECK_TIME: MvnCodeBE;
  BANKING_ACCOUNT_NUMBER_INVALID: MvnCodeBE;
  BANKING_ACCOUNT_INVALID: MvnCodeBE;
  BANKING_SERVICE_FAILED: MvnCodeBE;
  BANKING_BANK_CODE_INVALID: MvnCodeBE;
  BANKING_ACCOUNT_NAME_NOT_MATCH: MvnCodeBE;
} = {
  BANKING_UNHANDLED_EXCEPTION: {
    status: 500,
    ko: "시스템 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.",
    en: "An error has occurred. Please try again later.",
  },
  BANKING_SYSTEM_CHECK_TIME: {
    status: 500,
    ko: "금융기관 시스템 정기점검 시간입니다. 잠시 후 다시 시도해 주세요.",
    en: "The financial system is under inspection. Please try again later.",
  },
  BANKING_ACCOUNT_NUMBER_INVALID: {
    status: 400,
    ko: "계좌번호가 유효하지 않습니다.",
    en: "The account number is invalid.",
  },
  BANKING_ACCOUNT_INVALID: {
    status: 400,
    ko: "거래할 수 없는 계좌입니다.",
    en: "This account cannot be traded.",
  },
  BANKING_SERVICE_FAILED: {
    status: 500,
    ko: "뱅킹 서비스 오류가 발생했습니다.",
    en: "Banking service error has occurred.",
  },
  BANKING_BANK_CODE_INVALID: {
    status: 400,
    ko: "은행코드가 유효하지 않습니다.",
    en: "The bank code is invalid.",
  },
  BANKING_ACCOUNT_NAME_NOT_MATCH: {
    status: 400,
    ko: "계좌 명의가 일치하지 않습니다.",
    en: "The account name does not match.",
  },
};

MvnCodeInitiate(BANKING_CODE);
